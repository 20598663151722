import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';


let withdraw = () => {

  return {
    // TODO: создание нового вывода средств
    postWithdrawOrder: function (data) {

      return ensure_auth_token()
          .then(function() {
            return axios.post(`/api/v1.0/withdraw/save/`, data)
          })
          .then( (response) => {
              return(response.data);
          })
          .catch( (error) => {
            console.error(error);
          });
    },

    updateWithdrawOrder: function ( status , order_id, data ) {
      return ensure_auth_token()
        .then(function() {
          return axios.put(`/api/v1.0/withdraw/${status}/${order_id}/`, data)
        })
        .then( response => {
          return response
        })
        .catch( error => {
          console.log(error);
          return(error);
        });
    },

    updateWithdrawOrderData: function ( id, data ) {
      return ensure_auth_token()
        .then(function() {
          return axios.put(`/api/v1.0/withdraw/data/${id}`, data)
        })
        .then( response => {
          return response
        })
        .catch( error => {
          console.log(error);
          return error;
        });
    },

    getWithdrawSumsForCalendar: function (calendar_id) {
      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/withdraw/${calendar_id}/sums/`)
          })
          .then(function (response) {

            if(response.status >= 200 && response.status < 300)
              return(response.data);
            })
          .catch(function (error) {
            console.log(error);
            return(error);
          });
    },

    getWithdrawHistoryByCalendar: function (calendar_id) {
      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/withdraw/${calendar_id}/history/`)
          })
          .then(function (response) {

            if(response.status >= 200 && response.status < 300)
              return(response.data);
          })
          .catch(function (error) {
            console.log(error);
            return(error);
          });
    },

    getLastWithdrawOrderNumber: function (calendar_id) {
      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/withdraw/${calendar_id}/lastorder/`)
          })
          .then(function (response) {

            if(response.status >= 200 && response.status < 300)

              return(response.data.last);
          })
          .catch(function (error) {
            console.log(error);
            return(error);
          });
    },

    getTotalWidthdrawSum: function (calendar_id) {
      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/withdraw/${calendar_id}/getTotalSum/`)
          })
          .then(function (response) {

            if(response.status >= 200 && response.status < 300)

              return(response.data.sum);
          })
          .catch(function (error) {
            console.log(error);
            return(error);
          });
    },

    getWithdrawalPayments (status) {
      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/withdraw/payments/${status}/`)
          })
          .then( res => {
            return res.data;
          })
          .catch( error => {
            console.log(error);
            return(error);
          })
    },

  }

}

export default withdraw();
