import {
  SET_PAYMENT_SUM,
  SET_PAYMENT_TABLE,
  SET_PAYMENT_POSITION,
  UNSET_PAYMENT_TABLE,
  TOGGLE_CHECKBOXES
} from '../constants/Payments';


const	initialState	=	{
		paymentPosition:	0,
    paymentTable: [],
    paymentSum: 0,
}


export default function paymentInfo(state = initialState, action) {

  switch (action.type) {


    case SET_PAYMENT_TABLE:


      return {...state, paymentTable: action.payload}

    case UNSET_PAYMENT_TABLE:

      return {...state, paymentTable: []}

    case TOGGLE_CHECKBOXES:

      {
        let { paymentTable } = {...state};
        let new_payment_table = paymentTable.map( payment => {

          payment.isChecked = action.payload
          return payment;

      })

      return {...state, paymentTable: new_payment_table}
    }


    case SET_PAYMENT_SUM:

    let paymentPosition = 0,
        paymentSum      = 0;

    state.paymentTable.forEach(o => {



      if(o.isChecked)
      {

        paymentSum += +(o.document_sum || 0);
        paymentPosition++;
      }
    })

    return {...state, paymentPosition: paymentPosition, paymentSum: paymentSum}

    default:
      return state
    }
}
