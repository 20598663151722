import React, {Component} from 'react';
import aux from '../Auxiliary/auxiliary'

const config = require('../../../../server/.config.js');


class ActinizationRow extends Component {
  render(){

    let {item} = this.props;

    return(

      <tr>

        <td className={`myTooltip ${item.status}`} data-tooltip={aux.paymentTooltip[item.status]}></td>
        <td>{aux.convertDate(item.created_date)}</td>
        <td>#{item.document_num}
            <div className='actComment'>
              <small className='actComment_inner'>
                {item.description}

              </small>
            </div>
        </td>
        <td><span className='shortName'>Спорт вокруг.,&nbsp; </span><span className='shortName'>#0</span></td>
        <td>  </td>
        <td >{aux.splitDigits(item.document_sum)}</td>
        <td>

              {aux.splitDigits(item.document_sum)}

        </td>
        <td>
          <a className='pseudoLink' href={'https://' + config.APIHOST + `/competitions/${item.competition_id}/`} target="_blank" rel="noopener">#{item.competition_id}&nbsp;{item.competition_title ? aux.cutString(item.competition_title) : null}</a>
        </td>

      </tr>

    )
  }
}


export default ActinizationRow;
