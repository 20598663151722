import {
  CALENDAR_REQUEST,
  CALENDAR_FAIL,
  CALENDAR_SUCCESS,
} from '../constants/Calendar';

const initialState = JSON.parse(localStorage.getItem('calendar_id')) || {};//JSON.parse(window.localStorage.getItem('calendar_id')) || {}

export default function calendar(state = initialState, action) {

  switch (action.type) {

    case CALENDAR_REQUEST:

      return action.payload;

    default:
      return state
    }
}
