import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
// import jQuery from 'jquery';

import _competitionsApi from '../../services/competitionsApi.js';
import _withdrawApi from '../../services/withdrawApi.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WithdrawActions from '../../actions/WithdrawActions';

import aux from '../Auxiliary/auxiliary.js';
import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import TableRow from './TableRow';
import Confirm from './Confirm.jsx';

import _ from 'underscore';

class NavigationBackToMain extends Component {
  render() {
    return (
      <div className="nav-back">
        <Link to="/">вернуться в личный кабинет</Link>
      </div>
    )
  }
}

class Head extends Component {
  render() {
    return (
      <div className="heading">
        <img className="nav-img" src="/img/history.png" />
        <span className="nav-text">
          <strong>Вывод средств</strong>
        </span>
      </div>
    );
  }
}

class WithdrawalInfo extends Component {

  _handleConfirm(event) {
    event.preventDefault();

    // if (this.props.withdrawSum > 0) {
    if (this.props.withdrawSum != 0) {
      this.props.onConfirm(true);
    }
  }

  render() {
    let { availableSum, withdrawSum } = this.props;
    let disabled = this.props.withdrawSum != 0 ? '' : 'disabled';

    return (
      <div className="withdrawal-info">
        <div className="available">
          к выводу доступно: <span id="available">{aux.splitDigits(availableSum)}&#8381;</span>
        </div>
        <div className={`withdrawal-button  ${disabled}`}>
          <Link to="" onClick={this._handleConfirm.bind(this)}>
            Вывести <span id='toWithdraw'> {aux.splitDigits(withdrawSum)}&#8381;</span>
          </Link>
        </div>
        <div className="money-left">
          останется: <span id="money-left">{aux.splitDigits(+availableSum - +withdrawSum)}&#8381;</span>
        </div>
      </div>
    );
  }
}

class Table extends Component {
  state = {
    competitions: []
  }

  render() {
    return (
      <div>
        <table id="table" className="table ">
          <thead>
            <tr>
              <td className="custom-width"></td>
              <td>договор</td>
              <td>назначение платежа</td>
              <td>осталось вывести</td>
              <td>вывести</td>
              <td>сумма к выводу</td>
            </tr>
          </thead>
          <TableBody withdrawTable={this.props.withdrawTable} />
        </table>
      </div>
    );
  }
}

class TableBody extends Component {
  state = {}

  render() {
    let rows = [];
    let { withdrawTable } = this.props;

    for (let prop in withdrawTable) {
      rows.push(<TableRow item={withdrawTable[prop]} itemKey={prop} key={prop} />)
    }

    return (
      <tbody>
        {rows}
      </tbody>
    )
  }
}

class Withdrawal extends Component {
  state = {
    confirm: false,
    isLoadng: false,
  }

  componentDidMount() {
    let withdrawTable = {},
      { calendar, actions } = this.props,
      availableSum = 0,
      selected = null,
      checked = true,
      withdrawSum = null,
      url = this.props.location.pathname.split('/');

    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');


    if (url[2]) {
      selected = url[2];
    }

    if (typeof activeCalendar === 'number') {
      this.setState({
        ...this.state,
        isLoadng: true,
      });

      _competitionsApi
        .getWithdrawalsListByCalendarId(activeCalendar)
        .then(data => {
          data.forEach(o => {
            // if (o.withdraw <= 0) return;

            let sum = o.withdraw;

            if (selected) {
              checked = o.competition_id == selected ? true : false;
              withdrawSum = o.competition_id == selected ? sum : null;
            }
            else {
              withdrawSum = sum;
            }

            let remains = withdrawSum ? +sum - +withdrawSum : null;
            let payment_terms = null;
            switch (o.user_payment_button_title) {
              case 'pay': payment_terms = 'Стартовые взносы'; break;
              case 'donate': payment_terms = 'Пожертвования'; break;
              case 'contribute': payment_terms = 'Целевые взносы'; break;
              default: payment_terms = o.receipt_text_custom || 'Стартовые взносы'; break;
            }

            withdrawTable[o.competition_id + '=>' + o.legalentity_id + '=>' + payment_terms] = {
              ...o,
              payment_terms,
              isChecked: checked,
              sum: sum,
              withdrawSum: withdrawSum,
              remains: remains,
            }
            availableSum += sum;
          });

          actions.setAvailableSum(availableSum)
          actions.setWithdrawTable(withdrawTable)
          actions.aggregateSumToWithdraw()
          this.setState({ ...this.state, isLoadng: false });
        });
    }
  }

  componentWillUnmount() {
    this.props.actions.unSetWithdrawTable(false)
  }

  handleConfirm() {
    this.setState({ ...this.state, confirm: true })
  }

  handleCancel() {
    this.setState({ ...this.state, confirm: false })
  }

  handleTrigger() {
    this.props.history.push('/history');
  }

  render() {
    let { confirm, isLoadng } = this.state,
      { withdrawTable, withdrawSum, sum, calendar } = this.props;
    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');

    const withdrawalContent = (
      <div>
        <WithdrawalInfo availableSum={sum} withdrawSum={withdrawSum} onConfirm={this.handleConfirm.bind(this)} />
        {isLoadng ? <LoadingBlock /> : <Table withdrawTable={withdrawTable} />}
      </div>
    )

    const confirmContent = (
      <Confirm
        withdrawTable={withdrawTable}
        onUpdate={this.handleCancel.bind(this)}
        onTrigger={this.handleTrigger.bind(this)}
        calendar={activeCalendar}
        author_uid={this.props.uinfo ? this.props.uinfo.user_uuid : 0}
      />
    )

    return (
      <div className="wrap-inner">
        <div className="withdrawal">
          <NavigationBackToMain />
          <Head />

          {confirm ? confirmContent : withdrawalContent}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    calendar: state.calendar,
    ...state.withdrawData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(WithdrawActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal)
