import React, {Component} from 'react';

import BackToMain from '../Auxiliary/BackToMain.js';
import ActinizationClosureTable from './ActinizationClosureTable.js';

import _ from 'underscore';

class ActinizationClosure extends Component {

  checkAccess(val){
    if(val){
      this.props.router.push('/');
    }
  }

  render(){

    return(
      <div>

        <BackToMain />
        <Heading />
        <ActinizationClosureTable denied={this.checkAccess.bind(this)}/>

      </div>
    )

  }
}

class Heading extends Component {
  render(){
    return(

      <div className="heading">

          <span className="nav-text">
              <strong>Закрытие покупок</strong>
          </span>

      </div>

    )
  }
}

export default ActinizationClosure;
