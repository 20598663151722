import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BackToMain extends Component {
    render() {
        return (
            <div className="nav-back">
                <Link to="/">вернуться в личный кабинет</Link>
            </div>
        )
    }
}

export default BackToMain;
