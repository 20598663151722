import React, { Component } from 'react';

import _competitionsApi from '../../services/competitionsApi.js';

import _ from 'underscore';
import './Competitions.css';
import aux from '../Auxiliary/auxiliary.js';
import BackToMain from '../Auxiliary/BackToMain.js';
import CompetitionsTable from './CompetitionsTable.jsx';

class Competitions extends Component {
  state = {
    competitions: [],
    quantity: 0,
  }

  componentDidMount() {
    if (typeof this.props.activeCalendar === 'number') {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (typeof this.props.activeCalendar === 'number' && this.props.activeCalendar != prevProps.activeCalendar) {
      this.loadData();
    }
  }

  loadData = () => {
    _competitionsApi.getCompetitionsListByCalendarId(this.props.activeCalendar, { rowcount: 3 })
      .then(competitions => {
        this.setState({ ...this.state, competitions: competitions.list, quantity: competitions.total });
      })
  }


  render() {

    let { competitions, quantity } = this.state;
    let { activeCalendar } = this.props;

    return (
      <div className="wrap-inner">
        <div id="competitions" className="competitions">
          <aux.Head url="/competitions" urlTitle="добавить соревнование" img="img/competitions.png" headTitle="Мои соревнования" />
          <CompetitionsTable calendar={activeCalendar} competitions={competitions} quantity={quantity} />
        </div>
      </div>
    );
  }
}

export default Competitions;
