import React, {Component} from 'react';
import './Withdrawal.css';

import _competitionsApi from '../../services/competitionsApi.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as WithdrawActions from '../../actions/WithdrawActions';

import aux from '../Auxiliary/auxiliary.js';

const config = require('../../../../server/.config.js');

class TableRow extends Component {
  state = {
    remains: 0,
  }

  _handlerCheck(event){
    let next                      = event.target.parentNode.nextSibling.children[0],
        {withdrawTable, actions}  = this.props,
        _next                     = withdrawTable[next.name];

    if (event.target.checked) {
      _next.isChecked             = true;
      _next.withdrawSum           = +next.value;
      let remains                 = withdrawTable[next.name].sum - withdrawTable[next.name].withdrawSum;

      // if(remains && remains > 0){
      if (remains && remains != 0) {
        if (+next.value === 0) {
          next.value                            = withdrawTable[next.name].sum;
          withdrawTable[next.name].withdrawSum  = withdrawTable[next.name].sum;
          this.setState({remains: 0})
        }
      }
      actions.setWithdrawTable(withdrawTable);
      actions.aggregateSumToWithdraw();
    }
    else {
      _next.withdrawSum = 0;
      _next.isChecked = false;
    }

    actions.setWithdrawTable(withdrawTable);
    actions.aggregateSumToWithdraw();
  }

  _handlerChange(event) {
    let {withdrawTable, actions} = this.props,
        {name, value} = event.target;

    withdrawTable[name].withdrawSum = +value;
    withdrawTable[name].remains = +withdrawTable[name].sum - +withdrawTable[name].withdrawSum;

    actions.setWithdrawTable(withdrawTable);
    actions.aggregateSumToWithdraw();
  }

  _handlerBlur(event) {
    let {withdrawTable, actions} = this.props,
        {name, value} = event.target,
        _prev = event.target.parentNode.previousElementSibling.children[0];

    // if (+value > 0) {
    if (+value != 0) {
      _prev.checked = true;
      withdrawTable[name].isChecked = true;
      withdrawTable[name].withdrawSum = +value;

      if (+value > withdrawTable[name].sum) {
        withdrawTable[name].withdrawSum = withdrawTable[name].sum;
        event.target.value = withdrawTable[name].sum;
      }
      let remains = +withdrawTable[name].sum - +withdrawTable[name].withdrawSum;

      this.setState({remains: remains})

      actions.setWithdrawTable(withdrawTable);
      actions.aggregateSumToWithdraw();
    }
  }

  handleSelect(event){
    let {withdrawTable, actions} = this.props,
        _name = event.target.closest('.table-item').getAttribute('name');

    withdrawTable[_name].contractId = event.target.value;

    actions.setWithdrawTable(withdrawTable);
  }

  render() {
    const {item, itemKey, withdrawTable} = this.props;

    let remains =   <div>останется: {aux.splitDigits(this.state.remains)} &#8381; </div>;

    return(
      <tr className="table-item" name={item.competition_id}>
        <td className="custom-width">
          <div className="no-align">
            <a href={'https://' + config.APIHOST + `/competitions/${item.competition_id}/`} target="_blank" rel='noopener'>{`#${item.competition_id} ${item.title}`}</a>
          </div>
        </td>
        <td>#{item.legalentity_id} {item.legalentity_display_name}</td>
        <td>{item.payment_terms}</td>
        <td className="money-left"  >
          {aux.splitDigits(item.sum)}
        </td>
        <td >
          <input className="isChecked" type="checkbox" onChange={this._handlerCheck.bind(this)}  defaultChecked={withdrawTable[itemKey].isChecked }/>
        </td>
        <td className="summary" >
          <input name={itemKey} style={{textAlign: 'right'}}  type="number" min="0" onBlur={this._handlerBlur.bind(this)} max={item.sum} onChange={this._handlerChange.bind(this)} defaultValue={withdrawTable[itemKey].withdrawSum} />
          {this.state.remains != 0 && withdrawTable[itemKey].isChecked === true? remains : null}
        </td>
      </tr>
    )
  }
}


function	mapStateToProps(state)	{
    return	{
        calendar:	         state.calendar,
        sum:	             state.withdrawData.sum,
        withdrawTable:     state.withdrawData.withdrawTable,
    }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(WithdrawActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableRow)
