import React, { Component } from 'react';
import aux from '../Auxiliary/auxiliary';
import _withdrawApi from '../../services/withdrawApi.js';
import _contractsApi from '../../services/contractsApi.js';

import _ from 'lodash';


class Confirm extends Component {
  state = {
    paymentNumber: 0,
    loading: false
  }

  cancelClickHandler() {
    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  confirmClickHandler() {
    let { paymentNumber } = this.state;
    let { author_uid, calendar } = this.props;
    let data, tasks = [];

    this.setState(prev => {
      return ({
        ...prev,
        loading: true
      });
    });

    for (let i in this.state.items) {
      let o = this.state.items[i];

      paymentNumber++;
      data = {
        calendar_id: calendar,
        legalentity_id: o.legalentity_id,
        payment_terms: o.payment_terms,
        document_num: paymentNumber,
        author_uid: author_uid,
        competitions: _.map(o.competitions, cn => {
          return {
            competition_id: cn.competition_id,
            withdrawal_sum: cn.withdrawSum
          }
        })
      }
      tasks.push(_withdrawApi.postWithdrawOrder(data));
    }

    Promise.all(tasks)
      .then(this.props.onTrigger);
  }


  static getDerivedStateFromProps(props, state) {
    let items = [];
    for (let prop in props.withdrawTable) {
      let o = props.withdrawTable[prop];
      if (!o.isChecked) continue;
      const _key = `${o.legalentity_id}=>${o.payment_terms}`;
      if (!items[_key]) {
        items[_key] = {
          legalentity_id: o.legalentity_id,
          title: o.legalentity_display_name,
          payment_terms: o.payment_terms,
        };
        items[_key].competitions = [];
      }
      items[_key].competitions.push(o);
    }
    return {
      ...state,
      items: items
    }
  }


  componentDidMount() {
    let { calendar } = this.props;

    _withdrawApi.getLastWithdrawOrderNumber(calendar).then(
      data => {
        this.setState({ ...this.state, paymentNumber: +data })
      }
    )
  }

  render() {
    let { paymentNumber, loading } = this.state;

    return (
      <div className="confirm">
        <div className="confirm_checkStatement">Пожалуйста, проверьте свою заявку еще раз.</div>
        {loading &&
          <div className="loadingBlock_wrapper">
            <div className="loadingBlock_img" />
          </div>
        }
        {!loading &&
          <div className="confirm_wrapper">

            <ConfirmTable table={this.state.items} paymentNumber={paymentNumber} />

            <div className="confirm_buttons col-md-12">
              <div className="confirm_buttons-wrapper">
                <div className={`confirm_buttons-ok`} onClick={this.confirmClickHandler.bind(this)}>
                  <span>Подтвердить</span>
                </div>
                <div className="confirm_buttons-cancel" onClick={this.cancelClickHandler.bind(this)}>
                  <span>Отмена</span>
                </div>
              </div>
            </div>

          </div>
        }
      </div>
    )
  }
}



class ConfirmTable extends Component {
  render() {

    let confirmRows = [],
      { paymentNumber, table } = this.props,
      totalSum = 0;

    for (let i in table) {
      paymentNumber++;
      confirmRows.push(
        <div key={paymentNumber}>
          <h3 className="confirm_title">Платеж {paymentNumber}</h3>
          <div className="row">
            <div className="col-md-3 fS15">Получатель: {`#${table[i].legalentity_id} ${table[i].title}`}</div>
          </div>
          <div className="row" style={{"marginBottom": "1em"}}>
            <div className="col-md-7 fS15">Назначение платежа: {`${table[i].payment_terms}`}</div>
          </div>
          {_.map(table[i].competitions, (confirmItem) => {
            totalSum += confirmItem.withdrawSum;
            return <ConfirmTableRow item={confirmItem} key={paymentNumber + '=' + confirmItem.competition_id} />;
          })}
        </div>
      );
    }

    return (
      <div className="col-md-12">
        <div className='row'>
          <div className='col-md-3 fS15 m1'><span>Сумма:</span>&nbsp; <span className='pull-right'>{aux.splitDigits(totalSum)}</span> </div>
        </div>
        {confirmRows}
      </div>

    )
  }
}

let ConfirmTableRow = () => ({
  render() {
    const { item } = this.props;

    return (
      <div className="row fS12">
        <span className="col-md-3 text-right">{aux.splitDigits(item.withdrawSum)}</span>
        <span className="col-md-9">{`#${item.competition_id} ${item.title}`}</span>
      </div>
    )
  }
})

export default Confirm;
