import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header.jsx'
import Page404 from './components/Page404/Page404.jsx'

import Contracts from './components/Contracts/Contract.jsx'
import TerminatedContracts from './components/Contracts/TerminatedContracts.js'
import AllContracts from './components/Contracts/AllContracts.js'
import ExistingContract from './components/Contracts/ExistingContract.js'

import Competitions from './components/Competitions/AllCompetitionsPage.js'
import Settings from './components/Settings/Settings.jsx'
import Withdrawal from './components/Withdrawal/Withdrawal.jsx'
import Applications from './components/Applications/Applications.jsx'
import History from './components/PaymentHistory/History.jsx'
import PaymentClosure from './components/PaymentClosure/PaymentClosure.js'
import ActinizationClosure from './components/ActinizationClosure/ActinizationClosure.js'
import Home from './components/Home/Home.jsx'
import Actinization from './components/Actinization/ActinizationPage.js'
import Helper from './utils/helper.js';

import '../less/main.less';

export class Routes extends React.Component {
  wrapped = (Component) => {
    let props = this.props;
    if(this.props.isAuthenticated){
      return (
        class extends React.Component {render = () => <Component {...props} {...this.props} />}
      );
    }
    return (
      class extends React.Component {render = () => <Page404 />}
    );
  }

  render = () => {
    return (
      <BrowserRouter>
        <div>
          <Header role={this.props.role} />
          <div className='container'>
            <Switch>
              <Route exact path='/' component={this.wrapped(Home)} />
              <Route path='/contract/new' component={this.wrapped(Contracts)} />
              <Route path='/contract/:id' component={this.wrapped(ExistingContract)} />
              <Route path='/contracts/' component={this.wrapped(AllContracts)} />
              <Route path='/contracts/terminated/' component={this.wrapped(TerminatedContracts)} />
              <Route path='/settings' component={this.wrapped(Settings)} />
              <Route path='/withdrawal' component={this.wrapped(Withdrawal)} />
              <Route path='/applications' component={this.wrapped(Applications)} />
              <Route path='/history' component={this.wrapped(History)} />
              <Route path='/payments' component={this.wrapped(PaymentClosure)} />
              <Route path='/purchases' component={this.wrapped(ActinizationClosure)} />
              <Route path='/competitions' component={this.wrapped(Competitions)} />
              <Route path='/actinization' component={this.wrapped(Actinization)} />
              <Route path='/auth' component={Helper} />
              <Route component={Page404} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

/* <Route path='confirm' component={Confirm} /> */
