import React, { Component } from 'react';
import aux from '../Auxiliary/auxiliary'

const config = require('../../../../server/.config.js');



class WithdrawRow extends Component {
  render() {

    let { item } = this.props;

    return (

      <tr>

        <td className={`myTooltip ${item.status}`} data-tooltip={aux.paymentTooltip[item.status]}></td>
        <td>{aux.convertDate(item.created_date)}</td>
        <td>#{item.document_num}</td>
        <td><span className='shortName'>{item.contract.they_companyname},&nbsp;</span><span className='shortName'>#{item.contract.contract_num}</span></td>
        <td>{item.payment_terms || ''}</td>
        <td >{aux.splitDigits(item.document_sum)}</td>
        <td>{
          item.competitions.map((obj, i) => {
            return <div key={i}>{aux.splitDigits(obj.withdrawal_sum)}</div>
          })
        }
        </td>
        <td>
          {
            item.competitions.map((obj, i) => {
              return <a className='pseudoLink' key={i} href={'https://' + config.APIHOST + `/competitions/${obj.competition_id}/`} target="_blank" rel="noopener">#{obj.competition_id} &nbsp;{aux.cutString(obj.competition_title)}</a>
            })


          }


        </td>

      </tr>

    )
  }
}


export default WithdrawRow;
