import React, { Component } from 'react';
import classNames from 'class-names';

class Calendars extends Component {

  state = {
    calendars: []
  }

  selectHandler(id) {

    this.props.onUpdate(id);

  }

  static getDerivedStateFromProps(props, state) {

    const { calendars } = props;

    if (calendars && calendars.length) {
      return {
        ...state,
        calendars: props.calendars
      }
    } else {
      return state;
    }
  }

  componentDidUpdate() {
    const { calendars } = this.props;

    if (calendars && calendars.length) {

      const lastAC = +localStorage.getItem('lkAC');

      if (lastAC && calendars.findIndex(calendar => calendar.calendar_id === +lastAC) > -1) {
        this.selectHandler(lastAC)
      } else {
        localStorage.setItem('lkAC', +calendars[0].calendar_id)
        this.selectHandler(+calendars[0].calendar_id)

      }
    }
  }

  render() {

    let { calendars } = this.state,
      active = '',
      list = [];
    const { activeCalendar } = this.props;

    if (calendars && calendars.length) {

      calendars.forEach((calendar, i) => {

        active = activeCalendar && activeCalendar === calendar.calendar_id;

        list.push(<CalendarRow active={active} calendar={calendar} key={i} onCalendarSelect={this.selectHandler.bind(this)} />)

      })
    }

    return (
      <div>

        {list}

      </div>
    )
  }
}

class CalendarRow extends Component {

  _switchCalendar(e) {

    const { name } = e.target;

    e.preventDefault();
    localStorage.setItem('lkAC', +name)
    this.props.onCalendarSelect(+name)

  }

  render() {

    const { active, calendar } = this.props;

    return (
      <div className={classNames({ active: active })} >
        <a href="#" name={calendar.calendar_id} onClick={this._switchCalendar.bind(this)} >
          {calendar.title}
        </a>
      </div>
    )
  }

}

export default Calendars;
