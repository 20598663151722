export default function validation(name, value, bik, blur) {

  if(typeof value === 'undefined') return false;

  value = value.trim();
  let result = true;
  let they_bik = typeof bik !== 'undefined' ? bik : 0;


  switch (name) {
    case 'they_bik':

        if (typeof value === 'number') {
          value = value.toString();
        } else if (typeof value !== 'string') {
          value = '';
        }
        if (!value.length) {

          blur === true ? result === true : result = 'БИК пуст, заполните поле!';
          return result;

        } else if (/[^0-9]/.test(value)) {
          result = 'БИК должен содержать только цифры!';
          return result;
        } else if (value.length !== 9) {
          result = 'БИК должен состоять из 9 цифр!';
          return result;
        } else {
            return true;
        }
        return result;

      break;

    case 'they_inn':

         if (typeof value === 'number') {
           value = value.toString();
         } else if (typeof value !== 'string') {
           value = '';
         }
         if (!value.length) {
           blur === true ? result === true : result = 'ИНН пуст';
           return result;
         } else if (/[^0-9]/.test(value)) {
           result = 'ИНН может состоять только из цифр';
           return result;
         } else if ([10, 12].indexOf(value.length) === -1) {
           result = 'ИНН может состоять только из 10 или 12 цифр';
           return result;
         } else {
           var checkDigit = function (value, coefficients) {
             var n = 0;
             for (var i in coefficients) {
               n += coefficients[i] * value[i];
             }
             return parseInt(n % 11 % 10);
           };
           switch (value.length) {
             case 10:
               var n10 = checkDigit(value, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
               if (n10 === parseInt(value[9])) {
                 result = true;
               }
               break;
             case 12:
               var n11 = checkDigit(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
               var n12 = checkDigit(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
               if ((n11 === parseInt(value[10])) && (n12 === parseInt(value[11]))) {
                 result = true;
               }
               break;
           }
           if (!result) {
             result = 'Неправильное контрольное число';
             return result;
           }
         }
         return result;
      break;

    case 'they_kpp':

        if (typeof value === 'number') {
          value = value.toString();
        } else if (typeof value !== 'string') {
          value = '';
        }
        if (!value.length) {
          blur === true ? result === true : result = 'КПП пуст';
          return result;
        } else if (value.length !== 9) {
          result = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
          return result;
        } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(value)) {
          result = 'Неправильный формат КПП';
          return result;
        } else {
          result = true;
        }

        return result;
      break;

    case 'they_phone':

      let phone = /^((\+?7|8)(\s|\-)?)?((\(\d{3}\))|(\d{3}))?([ \-])?(\d{3}[\- ]?\d{2}[\- ]?\d{2})$/.test(value);

      if(!phone){
        result = 'Неверный формат телефона';
        return result
      } else {
        result = true;return result;
      }
      break;

    case 'they_email':
      let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value);
      if(!re){
        result = 'Неверный формат e-mail';
        return result
      } else {
        result = true;return result;
      }
      break;

    case 'they_account':

            if (typeof value === 'number') {
             value = value.toString();
           } else if (typeof value !== 'string') {
             value = '';
           }
           if (!value.length) {
              blur === true ? result === true : result = 'Р/С пуст';
              return result;

           } else if (/[^0-9]/.test(value)) {
              result = 'Р/С может состоять только из цифр';
              return result;

           } else if (value.length !== 20) {
              result = 'Р/С может состоять только из 20 цифр';
              return result;

           } else if(they_bik && they_bik.length){
             var bikRs = they_bik.toString().slice(-3) + value;
             var checksum = 0;
             var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
             for (var i in coefficients) {
               checksum += coefficients[i] * (bikRs[i] % 10);
             }
             if (checksum % 10 === 0) {
               result = true;
             } else {
                result = 'Неправильное контрольное число';
             }
           }
         return result;
      break;

    case 'they_coraccount':

        if (typeof value === 'number') {
          value = value.toString();
        } else if (typeof value !== 'string') {
          value = '';
        }

        if (!value.length) {
          blur === true ? result === true : result = 'К/С пуст';
          return result;

        } else if (/[^0-9]/.test(value)) {
          result = 'К/С может состоять только из цифр';
          return result;

        } else if (value.length !== 20) {
          result = 'К/С может состоять только из 20 цифр';
          return result;

        } else if(they_bik && they_bik.length){

          var bikKs = '0' + they_bik.toString().slice(4, 6) + value;
          var checksum = 0;
          var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
          for (var i in coefficients) {
            checksum += coefficients[i] * (bikKs[i] % 10);
          }
          if (checksum % 10 === 0) {
            result = true;
          } else {
            result = 'Неправильное контрольное число';
          }
        }
        return result;
      break;

    case 'they_signpatronimic':
       return true;

    case 'they_passportdate':


     if(!value.length){
       result = 'Дата не заполнена';
       return result;
     } else if(!/^(\d{2}\.\d{2}\.\d{4})$/.test(value) ){
       result =  'Формат даты должен быть: дд.мм.гггг';
       return result;
     }

     let date = value.split('.');

     if(+date[0] <= 0 || +date[0] > 31) {
       result = 'День не может быть меньше 1 и больше 31';
       return result;
     } else if (+date[1] <= 0 || +date[1] > 12) {
       result = 'Месяц не может быть меньше 1 и больше 12';
       return result;
     } else if (+date[2] <= 1900 || +date[2] > new Date().getFullYear()) {
       result = `Год не может быть меньше 1900 и больше ${new Date().getFullYear()}`;
       return result;
     } else {
       result = true;
     }
     return result;

     break;

    case 'they_passportnum':

     if(!/^(\d{4}\s?\d{6})$/.test(value)){
       result = 'Неверный формат. Пример: 1234 123456'
       return result;
     } else {
       result = true;return result;
     }

     break;

    default:
      if(!value&&!value.length > 0)
      {
        result = 'Заполните поле!';
        return result;
      } else if(/\s\s+/.test(value)){
        result = 'Поле не должно содержать двух и более пробелов подряд';
        return result;
      } else {
        result = true;return result;
      }
  }

}
