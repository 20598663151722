import React, {Component} from 'react';
import aux from '../Auxiliary/auxiliary.js';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import BackToMain from '../Auxiliary/BackToMain';


class Head extends Component {
    render() {
        return (
            <div className="heading">

                <span className="nav-text">
                    <strong>Закрытие переводов</strong>

                </span>
            </div>
        );
    }
}

class HeadBlock extends Component {
  state = {
    withRejected: false
  }

  static getDerivedStateFromProps(props, state){
    return {
      ...state,
      withRejected: props.withRejected
    }
  }

  accessDenied(val) {
    if(val){
      this.props.denied(true);
    }
  }

  componentDidMount(){

    const { role } = this.props;

    role !== 'admin' ? this.accessDenied(true) : null;

  }

  checkHandler = (e) => {

    e.preventDefault;

    console.log('click');
  }

  changeWithRejectedWW = (e) => {
    this.props.changeWithRejected(e.target.checked);
  }

  render(){


    let { paymentSum, paymentPosition } = this.props,
        disabled = paymentSum > 0 ? '' : 'disabled';




    return(


      <div>
        <BackToMain />
        <Head />

        <div className='col-md-4 col-md-offset-8'>
          <div>выгрузка в клиент-банк</div>
          <div>всего: {paymentPosition}</div>
          <div>на сумму: {paymentSum}&#8381;</div>

          <div className={`lk-button  ${disabled}`}>
              <Link to="" onClick={this.checkHandler}>
                  <span id='toWithdraw'> выгрузить </span>
              </Link>
          </div>

          <div>
            <input
              type="checkbox"
              name="with-rejected"
              checked={this.state.withRejected}
              onChange={this.changeWithRejectedWW}
            />
            <span>Показать отклоненные</span>
          </div>

        </div>

      </div>

    )
  }

}

function	mapStateToProps(state)	{
    return	{
        paymentSum:	     state.payment.paymentSum,
        // paymentTable:    state.payment.paymentTable,
        role:            state.auth.user.role,

        paymentPosition:     state.payment.paymentPosition,

    }
}


export default connect(mapStateToProps, null)(HeadBlock);
