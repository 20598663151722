import React, { Component } from 'react';
import { connect } from 'react-redux';

import _docflowApi from '../../services/docflowApi.js';

import aux from '../Auxiliary/auxiliary.js';
import BackToMain from '../Auxiliary/BackToMain.js';

import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import WithdrawRow from './WithdrawRow';
import ActinizationRow from './ActinizationRow';


class History extends Component {
  render() {

    return (
      <div>
        <BackToMain />
        <aux.Head img="img/history.png" headTitle="История выводов" />

        <Table calendar={this.props.calendar} />

      </div>
    )
  }
}


class Table extends Component {
  state = {
    history: [],
    // fullTable: false,
    sum: 0,
    loading: false,
    activeCalendar: 0
  }

  componentDidMount() {
    const { calendar } = this.props;
    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');
    if (activeCalendar === 0) return false;
    this.loadData(activeCalendar);
  }

  componentDidUpdate(prevProps, prevState) {
    const { calendar } = this.props;
    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');
    if (activeCalendar === 0 || activeCalendar == prevState.activeCalendar) return false;
    this.loadData(activeCalendar);
  }


  loadData = (activeCalendar) => {
    let { history, sum } = this.state;

    this.setState({ ...this.state, activeCalendar: activeCalendar, loading: true, error: false });
    _docflowApi.getHistoryByCalendar(activeCalendar)
      .then(
        data => {

          if (data && data.historyData) {
            history = data.historyData

            history.sort(aux.historySort);
            // history.sort(aux.idSort);

            this.setState({ ...this.state, loading: false, error: false, history: history, sum: data.sum })
          }
          else {
            this.setState({ ...this.state, loading: false, error: false })
          }
        }
      )
      .catch(() => {
        this.setState({ ...this.state, loading: false, error: true })
      })
  }

  render() {

    let rows = [],
      showFullTable = '',
      { history, sum } = this.state;

    history.forEach((o, i) => {

      // if(!fullTable){
      //   if(i >= 3) return;
      // }
      if (o.document_type === 'with') {

        rows.push(<WithdrawRow item={o} key={i} />)
      } else if (o.document_type === 'act') {
        rows.push(<ActinizationRow item={o} key={i} />)
      }

    })

    return (
      <div>
        {this.state.loading && !this.state.error && <LoadingBlock />}
        {this.state.error && <div style={{marginTop: '100px', padding: '20px'}}>Не удалось загрузить данные по этому календарю. Вернитесь на главную страницу и выберите календарь, по которому вы ходтите просмотреть данные</div>}
        {!this.state.loading && !this.state.error &&
          <table id="table" className="commonTable historyTable">

            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>платеж</td>
                <td>договор</td>
                <td>назначение платежа</td>
                <td>сумма выводов</td>
                <td >{aux.splitDigits(sum)}</td>
                <td></td>

              </tr>

            </thead>

            <tbody>

              {rows}

            </tbody>



          </table>
        }
      </div>
    )

  }
}


function mapStateToProps(state) {
  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, null)(History)
