import {
  SET_SUM,
  CALCULATE_SUM,
  SET_TABLE,
  SET_CONTRACT,
  UNSET_TABLE,

} from '../constants/Withdraw';

const	initialState	=	{
		sum:	0,
    withdrawTable: {},
    withdrawSum: 0
}


export default function withdrawInfo(state = initialState, action) {

  switch (action.type) {

    case SET_SUM:

      return {...state, sum: action.payload}

    case SET_TABLE:
      return {...state, withdrawTable: action.payload}

    case UNSET_TABLE:

      return {...state, confirmItems: {}, withdrawTable: {}}

    case CALCULATE_SUM:

    let withdrawSum = 0;

    for(let prop in state.withdrawTable){
      if(state.withdrawTable[prop].isChecked)
      {
        withdrawSum += +(state.withdrawTable[prop].withdrawSum || 0);
      }
    }

    return {...state, withdrawSum: withdrawSum}

    default:
      return state
    }
}
