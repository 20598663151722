import React, { Component } from 'react';
import aux from '../Auxiliary/auxiliary.js';
import { Link } from 'react-router-dom';

import HeadBlock from './HeadBlock';
import PaymentTable from './PaymentTable';

import _ from 'underscore';

class PaymentClosure extends Component {
  state = {
    withRejected: false
  }

  _isMount = false;


  componentDidMount() {
    this._isMount = true;
  }

  checkAccess(val) {
    if (val) {
      this.props.router.push('/')
    }
  }

  changeWithRejected = (val) => {
    if (!this._isMount) return;

    this.setState({
      ...this.state,
      withRejected: val
    });

  }

  componentWillUnmount() {
    this._isMount = false;
  }

  render() {

    return (

      <div>
        <HeadBlock
          denied={this.checkAccess.bind(this)}
          withRejected={this.state.withRejected}
          changeWithRejected={this.changeWithRejected}
        />
        <PaymentTable withRejected={this.state.withRejected} />
      </div>

    )
  }
}

export default PaymentClosure;
