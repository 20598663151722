import axios from 'axios';
import ensure_auth_token from '../services/ensure_auth_token';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { SET_CURRENT_USER } from '../constants/Auth';
const config = require('../../../server/.config.js');

export function setCurrentUser(user) {

  return{
    type: SET_CURRENT_USER,
    user
  }
}

export function loginOnNewWebsite() {
  return dispatch => {
    const _href = window.location.href;
    const url = 'https://' + config.APIHOST + '/sports/auth/?back_url=' + encodeURIComponent(_href);
    return location.replace(url);
  }
}

export function setToken(token) {

  return dispatch => {
    localStorage.setItem('lktoken', token);
    setAuthorizationToken(token !== 'verification_process' ? token : undefined );
    dispatch(setCurrentUser(token !== 'verification_process' ? jwtDecode(token) : {} ));
  }

}

export function checkToken(token) {

  return dispatch => {
    return ensure_auth_token()
    .then(function() {
      return axios.get(`/api/v1.0/auth/checkToken/${token}`);
    });


  }
}
