import React, { Component } from 'react';
import s from './actinization.less';



class ActinizationPageTitle extends Component {
  state = {}


  render() {

    return (
      <h3>Покупка товаров и услуг</h3>
    )
  }

}
export default ActinizationPageTitle;
