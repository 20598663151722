import React, { Component } from 'react';
import _contractsApi from '../../services/contractsApi.js'

import { bindActionCreators } from 'redux';
// import * as CalendarActions from '../../actions/CalendarActions';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'underscore';
import $ from 'jquery';
import validation from './validation';

import './Contract.css';

class NavigationBackToMain extends Component {
  render() {
    return (
      <div className="nav-back">
        <Link to="/">вернуться в личный кабинет</Link>
      </div>
    )
  }
}

class ContractBody extends Component {
  state = {
    type: 'nP',
    errors: {}
  };
  fullContract = false;


  setRadio = (e) => {
    this.setState({
      type: e.target.value,
    })
  }

  updateContractVisibility = () => {
    if (this.state.fullContract) {
      this.setState({ fullContract: false })
    } else {
      this.setState({ fullContract: true })
    }
  }

  render() {

    return (
      <div>
        <div className="head">
          <div className="contract-info">
            <div className="contract-info-first">
              <span className="contract-name">
                <span className="contract-status"></span>
                Агентский договор
              </span>
              <span className="contract-id">№1109</span>
              <span className="contract-download">Скачать</span>
            </div>
            <div className="contract-info-second">
              <span className="city">г.Москва</span>
              <span className="date">«01» августа 2016 г.</span>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="agreements">
          <div className={this.state.fullContract ? 'agreements-text' : 'agreements-text short'}>
            ____________________________________________________, в дальнейшем «Принципал», с одной стороны,
            Общество с ограниченной ответственностью «Спорт вокруг.СиЭрЭм» в лице Генерального директора
            Васильева М.В., действующего на основании Устава, именуемое в дальнейшем «Агент», с другой
            стороны», именуемые в дальнейшем «Стороны», заключили настоящий договор, в дальнейшем «Договор»,
            о нижеследующем:…
          </div>
          <div onClick={this.updateContractVisibility} className="agreements-show-full-text">
            {this.state.fullContract ? 'скрыть' : 'Показать полностью'}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="alertMessage alert alert-success text-center hideIt" >
          <strong> Данные успешно сохранены!</strong>
        </div>
        <div className="details">
          <h1>Реквизиты сторон</h1>
          <div className="details-left">
            <div className="organization-info">
              <div className="organization-text">
                <span className="info">
                  Общество с ограниченной ответственностью <br />
                  «Спорт вокруг. СиЭрЭм» (ООО «Спорт вокруг.
                  СиЭрЭм»)
                </span>

                юридический адрес: 101000, г. Москва, Архангельский<br />
                пер., д. 9, оф. 6<br />
                почтовый адрес: 109240, г. Москва, ул. Верхняя<br />
                Радищевская, д. 5, под. 2, оф. 25А<br />
                ИНН 9701040030<br />
                КПП 770101001<br />
                р/с 40702810300200000782<br />
                в АО «СМП Банк»<br />
                БИК 044525503,<br />
                к/с 30101810545250000503
              </div>
              <div className="clearfix"></div>
              <div className="organization-owner">
                генеральный директор
                Васильев М.В.
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="details-right">

            <div className='form-title' > Юридиеское лицо </div>

            <div className="form">

              <ContractForm type={this.state.type} activeCalendar={this.props.activeCalendar} />
            </div>

          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}



// <div className="natural">
//     <input checked={this.state.type == 'nP'} id="natural" onChange={this.setRadio} value="nP"
//            type="radio"/>
//            <label htmlFor="natural" >Физическое лицо </label>
// </div>
// <div className="legal">
//     <input checked={this.state.type == 'lP'} id="legal" onChange={this.setRadio} value="lP"
//            type="radio"/>
//            <label htmlFor="legal" >Юридическое лицо </label>
// </div>

class ContractForm extends Component {

  state = {

    they_account: "",
    they_bankname: "",
    they_bik: "",
    they_coraccount: "",
    they_email: "",
    they_inn: "",
    they_kpp: "",
    they_legaladdress: "",
    they_passportdate: "",
    they_passportfms: "",
    they_passportnum: "",
    they_phone: "",
    they_postaddress: "",
    they_signname: "",
    they_signpatronimic: "",
    they_signsurname: "",
    they_companyname: '',
    they_companyshortname: '',
    they_signposition: '',
    they_fio: '',

    isValid: {
      they_legaladdress: true,
      they_postaddress: true,
      they_passportdate: true,
      they_passportfms: true,
      they_passportnum: true,
      they_companyname: true,
      they_companyshortname: true,
      they_signname: true,
      they_signsurname: true,
      they_signpatronimic: true,
      they_signposition: true,
      they_email: true,
      they_inn: true,
      they_kpp: true,
      they_account: true,
      they_coraccount: true,
      they_bik: true,
      they_bankname: true,
      they_phone: true,
      they_fio: true,
    },
    readOnly: {
      they_coraccount: true,
      they_bankname: true,

    }

  }


  _getInputStyleName(isValid) {
    return isValid ? 'valid' : 'invalid';
  }

  _validateBik(value, blur) {

    let result = 'test';

    if (typeof value === 'undefined') return false;
    if (typeof value === 'number') {
      value = value.toString();
    } else if (typeof value !== 'string') {
      value = '';
    }


    if (!value.length) {

      blur == true ? result = 'skip' : result = 'БИК пуст, заполните поле!';

      return result;

    } else if (/[^0-9]/.test(value)) {
      result = 'БИК должен содержать только цифры!';
      return result;
    } else if (value.length !== 9) {
      result = 'БИК должен состоять из 9 цифр!';
      return result;
    } else {
      return true;
    }
    return result;
  }

  _sanitizeValue(value) {
    return value.trim();
  }

  _getDataFromApi(value) {

    let bankName = document.getElementsByTagName('input').they_bankname,
      corrAccount = document.getElementsByTagName('input').they_coraccount;

    let instance = axios.create();
    instance.defaults.headers.common = {};

    // console.log('checking...');

    axios
      .get(`https://www.bik-info.ru/api.html?type=json&bik=${value}`)
      .then(function (response) {

        // console.log('response ', response);

        if (response.status >= 200 && response.status < 300) {

          if (!response.data.error) {

            let isValid = Object.assign({}, this.state.isValid, { they_bik: true, they_bankname: true, they_coraccount: true });
            this.setState(Object.assign({}, this.state, { isValid: isValid }));

            bankName.style.border = 'none';
            corrAccount.style.border = 'none';

            // console.log('success');

            this.setState({
              they_bankname: response.data.namemini,
              they_coraccount: response.data.ks,
              readOnly: {
                they_bankname: true,
                they_coraccount: true
              }
            })
          } else {
            let isValid = Object.assign({}, this.state.isValid, { they_bik: false });
            this.setState(Object.assign({}, this.state, { isValid: isValid }));

            this.setReadOnly();
            bankName.style.border = 'solid 1px #dbe2e8';
            corrAccount.style.border = 'solid 1px #dbe2e8';
          }

        } else {
          throw new Error(response.data.error);
        }

      }.bind(this))
      .catch(function (error) {

        // console.log(error);

        this.setReadOnly();
        bankName.style.border = 'solid 1px #dbe2e8';
        corrAccount.style.border = 'solid 1px #dbe2e8';
        console.log(error);
      }.bind(this));
  }

  setReadOnly() {
    this.setState({
      they_bankname: '',
      they_coraccount: '',
      readOnly: {
        they_bankname: false,
        they_coraccount: false
      }
    })
  }

  handleSaveClick = (event) => {

    event.preventDefault();

    let myForm = document.getElementById('form'),
      elements = myForm.querySelectorAll('input'),
      isSubmit = true,
      isValid = {},
      data = {
        status: 'new',
      };

    for (let i = 0, el = elements.length; i < el; i++) {
      let input = elements[i],
        result = validation(input.name, input.value, this.state.they_bik);

      if (result !== true) {

        isSubmit = false;
        isValid[input.name] = false;


      } else {
        isValid[input.name] = true;
      }


      this.setState(Object.assign({}, this.state, { isValid: isValid }));
      data[input.name] = this._sanitizeValue(input.value);

    }

    if (isSubmit === true) {

      _contractsApi.createContract(data).then(
        data => {
          // console.log(data);

          $('.alertMessage').show();
          setTimeout(() => {
            $('.alertMessage').hide();
          }, 2500)


          this.setState({

            they_account: "",
            they_bankname: "",
            they_bik: "",
            they_coraccount: "",
            they_email: "",
            they_inn: "",
            they_kpp: "",
            they_legaladdress: "",
            they_passportdate: "",
            they_passportfms: "",
            they_passportnum: "",
            they_phone: "",
            they_postaddress: "",
            they_signname: "",
            they_signpatronimic: "",
            they_signsurname: "",
            they_companyname: '',
            they_companyshortname: '',
            they_signposition: '',


            isValid: {
              they_legaladdress: true,
              they_postaddress: true,
              they_passportdate: true,
              they_passportfms: true,
              they_passportnum: true,
              they_companyname: true,
              they_companyshortname: true,
              they_signname: true,
              they_signsurname: true,
              they_signpatronimic: true,
              they_signposition: true,
              they_email: true,
              they_inn: true,
              they_kpp: true,
              they_account: true,
              they_coraccount: true,
              they_bik: true,
              they_bankname: true,
              they_phone: true,
            },
            readOnly: {
              they_coraccount: false,
              they_bankname: false,

            }
          })
        }
      )
    }
  }

  handleBlur = (event) => {

    let { name, value } = event.target,
      { bik } = this.state;
    // let {name, value} = event.target,
    //     errorMsg = '',
    //     spanName  = `${name}_errorMsg`,
    //     validationResult = validation( name, value, this.state.they_bik );
    //
    // if(typeof validationResult !== 'boolean') {
    //     errorMsg = validationResult;
    //     validationResult = false;
    //     // document.getElementsByName(spanName)[0].innerText = errorMsg;
    //     // let node = document.createElement('span');
    //     // let text = document.createTextNode('Error!')
    //     // node.className = 'errorMsg';
    //     // node.appendChild(text);
    //     // event.target.parentNode.append(node);
    //
    //   }
    //   // else {
    //   //   // document.getElementsByName(spanName)[0].innerText = '';
    //   // }
    //
    // let isValid = Object.assign({}, this.state.isValid, {[name]: validationResult});
    // this.setState(Object.assign({}, this.state, {isValid: isValid}));


    // console.log(event.target.name);
    let isValid = this.state.isValid;

    let result = validation(name, value, bik, true)

    if (result !== true) {

      isValid[name] = false;

    } else {
      isValid[name] = true;
    }

    this.setState({ ...this.state, isValid: isValid });



  }

  _checkBik(event) {

    let { they_bik } = this.state,
      result = this._validateBik(they_bik, true);
    // let data = {};

    if (typeof result === 'boolean' && result === true) {
      // document.getElementsByName('they_bik_errorMsg')[0].innerText = '';
      this._getDataFromApi(they_bik)

    }
    else if (result === 'skip') {
      // console.log('skip');
    }



    else {

      let isValid = this.state.isValid;
      isValid['they_bik'] = false;
      this.setState({ ...this.state, isValid: isValid });


      // document.getElementsByName('they_bik_errorMsg')[0].innerText = result;

      //
      // let isValid = Object.assign({}, this.state.isValid, {they_bik: false});
      // this.setState(Object.assign({}, this.state, {isValid: isValid}));
      //
      // this.setState({
      //   readOnly: {
      //     they_bankname: false,
      //     they_coraccount: false
      //   }
      // })

    }
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {

    const { isValid, readOnly } = this.state,
      { type } = this.props;
    let contentByType = [];


    const naturalPerson = [
      <div key='they_signname' className="form-group">
        <input type='text'
          name='they_signname'
          className={this._getInputStyleName(isValid.they_signname)}
          onChange={this.handleChange}
          //  onBlur={this.handleBlur}
          value={this.state.they_signname}
          placeholder='Имя' />
      </div>,
      <div key='they_signsurname' className="form-group">
        <input type='text'
          name='they_signsurname'
          className={this._getInputStyleName(isValid.they_signsurname)}
          onChange={this.handleChange}
          value={this.state.they_signsurname}
          //  onBlur={this.handleBlur}

          placeholder='Фамилия' />
      </div>,
      <div key='they_signpatronimic' className="form-group">
        <input type='text'
          name='they_signpatronimic'
          //  onChange={this.handleChange}
          value={this.state.they_signpatronimic}
          placeholder='Отчество' />

      </div>,
      <div key='they_passportnum' className="form-group">
        <input type='text'
          name='they_passportnum'
          className={this._getInputStyleName(isValid.they_passportnum)}
          onChange={this.handleChange}
          value={this.state.they_passportnum}
          //  onBlur={this.handleBlur}
          placeholder='Серия и номер паспорта СССС НННННН' />

      </div>,
      <div key='they_passportfms' className="form-group">
        <input type='text'
          name='they_passportfms'
          className={this._getInputStyleName(isValid.they_passportfms)}
          onChange={this.handleChange}
          value={this.state.they_passportfms}
          //  onBlur={this.handleBlur}
          placeholder='Кем выдан паспорт' />

      </div>,
      <div key='they_passportdate' className="form-group">
        <input type='text'
          name='they_passportdate'
          className={this._getInputStyleName(isValid.they_passportdate)}
          onChange={this.handleChange}
          value={this.state.they_passportdate}
          onBlur={this.handleBlur}
          placeholder='Дата выдачи паспорта ДД.ММ.ГГГГ' />

      </div>



    ];

    const commonContent = [


      <div key='they_companyname' className="form-group">
        <input type='text'
          name='they_companyname'
          className={this._getInputStyleName(isValid.they_companyname)}
          value={this.state.they_companyname}
          onChange={this.handleChange}
          //  onBlur={this.handleBlur}
          placeholder='название компании полное' />

      </div>,
      <div key='they_companyshortname' className="form-group">
        <input type='text'
          name='they_companyshortname'
          className={this._getInputStyleName(isValid.they_companyshortname)}
          value={this.state.they_companyshortname}
          onChange={this.handleChange}
          //  onBlur={this.handleBlur}
          placeholder='название компании краткое' />

      </div>,
      <div key='they_legaladdress' className="form-group">
        <input type='text'
          name='they_legaladdress'

          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_legaladdress)}
          value={this.state.they_legaladdress}
          //  onBlur={this.handleBlur}
          placeholder='юридический адрес' />

      </div>,
      <div key='they_postaddress' className="form-group">
        <input type='text'

          name='they_postaddress'
          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_postaddress)}
          value={this.state.they_postaddress}
          //  onBlur={this.handleBlur}
          placeholder='почтовый адрес' />

      </div>,

      <div key='they_inn' className="form-group">
        <input type='text'
          name='they_inn'

          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_inn)}
          value={this.state.they_inn}
          onBlur={this.handleBlur}
          placeholder='ИНН' />
      </div>,

      <div key='they_kpp' className="form-group">
        <input type='text'
          name='they_kpp'
          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_kpp)}
          onBlur={this.handleBlur}
          value={this.state.they_kpp}
          placeholder='КПП' />

      </div>,
      <div key='they_account' className="form-group">
        <input type='text'
          name='they_account'

          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_account)}
          value={this.state.they_account}
          onBlur={this.handleBlur}
          placeholder='р/с' />
      </div>,
      <div key='they_bik' className="form-group">
        <input type='text'
          name='they_bik'

          className={this._getInputStyleName(isValid.they_bik)}
          onChange={this.handleChange}
          value={this.state.they_bik}
          onBlur={this._checkBik.bind(this)}
          placeholder='БИК' />

      </div>,
      <div key='they_bankname' className="form-group">
        <input type='text'
          name='they_bankname'

          readOnly={readOnly.they_bankname}
          className={this._getInputStyleName(isValid.they_bankname)}
          value={this.state.they_bankname}
          onChange={this.handleChange}
          //  onBlur={this.handleBlur}
          placeholder='банк' />
      </div>,
      <div className="form-group" key='they_coraccount'>
        <input type='text'
          name='they_coraccount'
          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_coraccount)}
          value={this.state.they_coraccount}
          readOnly={readOnly.they_coraccount}
          onBlur={this.handleBlur}
          placeholder='к/с' />
      </div>,

      <div key='they_phone' className="form-group">
        <input type='text'
          name='they_phone'
          onChange={this.handleChange}
          className={this._getInputStyleName(isValid.they_phone)}
          value={this.state.they_phone}
          //  onBlur={this.handleBlur}
          placeholder='контактный телефон' />

      </div>,
      <div key='they_signposition' className="form-group">
        <input type='text'
          name='they_signposition'
          className={this._getInputStyleName(isValid.they_signposition)}
          onChange={this.handleChange}
          value={this.state.they_signposition}
          //  onBlur={this.handleBlur}
          placeholder='должность руководителя' />
      </div>,

      <div key='they_fio' className="form-group">
        <input type='text'
          name='they_fio'
          className={this._getInputStyleName(isValid.they_fio)}
          onChange={this.handleChange}
          value={this.state.they_fio}
          //  onBlur={this.handleBlur}
          placeholder='ФИО руководителя' />
      </div>,

      <div className="form-group hidden" key='is_standard'>
        <input type='text'
          name='is_standard'
          readOnly='true'
          value='1' />
      </div>,
      <div className="form-group hidden" key='is_fiz'>
        <input type='text'
          name='is_fiz'
          readOnly='true'
          value={this.props.type === 'nP'} />
      </div>,
      <div className="form-group hidden" key='calendar_id'>
        <input type='text'
          name='calendar_id'
          readOnly='true'
          value={this.props.activeCalendar || +localStorage.getItem('lkAC')} />
      </div>,

    ]


    return (
      <div id='form' className='form-wrapper'>


        {commonContent}

        <div className='button-container'>

          <button onClick={this.handleSaveClick}
            className='contract-confirm-button '>
            <span>Заключить договор</span>
          </button>
        </div>
      </div>
    );
  }
}


class Contract extends Component {
  render() {

    return (
      <div className="wrap-inner">
        <div className="contract">
          {location.pathname == '/contract' ? <NavigationBackToMain /> : ""}
          <ContractBody activeCalendar={this.props.calendar} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, null)(Contract)
