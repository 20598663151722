import React, { Component } from 'react';

import _contractsApi from '../../services/contractsApi.js'

import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import BackToMain from '../Auxiliary/BackToMain.js';
import ContractTable from './ContractTable.js';


import { connect } from 'react-redux';


class TerminatedContracts extends Component {
  state = {
    contracts: {},
    isLoadng: false,
    activeCalendar: activeCalendar
  }

  componentDidUpdate(prevProps, prevState) {

    const { calendar } = this.props;

    let result = {},
      sorted = [];


    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');

    if (activeCalendar === 0 || activeCalendar == prevState.activeCalendar) return false;

    this.setState({
      isLoadng: true,
      activeCalendar: activeCalendar
    });

    _contractsApi.getContractsByCalendarId(activeCalendar, 'mainPage')
      .then(
        data => {

          data.forEach(contract => {
            if (contract.status == 'terminated') {
              sorted.push(contract)
            }
          })

          result.sorted = sorted;

          return result;
        }
      )
      .then(
        result => {
          this.setState({
            contracts: result,
            isLoadng: false,
          });
        }
      )
  }

  render() {

    const { isLoadng, contracts } = this.state;

    return (
      <div>

        <BackToMain />

        {isLoadng ? <LoadingBlock /> : <ContractTable contracts={contracts.sorted} />}

      </div>
    );
  }

}
function mapStateToProps(state) {

  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, null)(TerminatedContracts);
