import React, { Component } from 'react';

class LoadingBlock extends Component {

  render() {
    return (
      <div className='loadingBlock_wrapper'>
        <div className='loadingBlock_img' />
      </div>
    );
  }

}

export default LoadingBlock;
