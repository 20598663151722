import { combineReducers } from 'redux'
import calendar from './calendar'
import withdrawData from './withdrawData'
import auth from './auth'
import payment from './payment'


export const rootReducer = combineReducers({
  calendar,
  withdrawData,
  auth,
  payment
})
