import axios from 'axios';

const auth = function () {
    return {

        verification: function (search) {

          return axios.get(`/api/v1.0/auth/getToken`)
          .then(function (response) {
            console.log('AUTH: ', response);
            return(response);
          })
          .catch(function (error) {
            console.log(error);
            throw error;
          });

        }
    };
};

export default auth();
