import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';

const act = () => {

  return {
    postActData: function (data) {
      return ensure_auth_token()
      .then(function() {
        return axios.post(`/api/v1.0/act/save/`, data)
      })
      .then(function (response) {
        return(response.data);
      })
      .catch(function (error) {
        throw error;
      });
    },

    updateActData: function ( id, data ) {
      return ensure_auth_token()
        .then(function() {
          return axios.put(`/api/v1.0/act/data/${id}`, data)
        })
        .then( response => {
          return response
        })
        .catch( error => {
          console.log(error);
          return error;
        });
    },

    updatePurchase: function (status, order_id, data) {
      return ensure_auth_token()
      .then(function() {
        return axios.put(`/api/v1.0/act/${status}/${order_id}/`, data)
      })
        .then( response => {
          return response
        })
        .catch( error => {
          console.log(error);
        });
    },

    getActData: function () {
      return ensure_auth_token()
      .then(function() {
        return axios.get(`/api/v1.0/act/getData/`)
      })
        .then( response => {
            return response.data;
        })
        .catch( error => {
          throw error;
        })
    },

    getActinizationHistoryByCalendar: function (calendar_id) {
      return ensure_auth_token()
      .then(function() {
        return axios.get(`/api/v1.0/act/${calendar_id}/history/`)
      })
          .then(function (response) {
            if(response.status >= 200 && response.status < 300)
              return(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
    },

    getPurchases: function (status) {
      return ensure_auth_token()
      .then(function() {
        return axios.get(`/api/v1.0/act/${status}/purchases/`)
      })
        .then( res => {
          return res.data;
        })
        .catch( err => {
          console.error(err);
        })
    },
  }
}

export default act();
