import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import './Stats.css';
// import jQuery from 'jquery';
import _calendarApi from '../../services/calendarsApi.js'
// import _withdrawApi from '../../services/withdrawApi.js';
import _docflowApi from '../../services/docflowApi.js';
import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import aux from '../Auxiliary/auxiliary.js';
import _ from 'underscore'

class StatsRow extends Component {

  render() {
    return (
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <span>{this.props.text}</span>
        </div>
        <div className="result">
          <span className="number">  {aux.splitDigits(this.props.result)}</span> <span className="currency">{this.props.currency}</span>
          {
            this.props.type == 'deduce' ? <span className="span-link"><Link className='pseudoLink' to="/withdrawal">вывести</Link></span> : ''
          }
          {
            this.props.type == 'competitions' ? <span className="span-link">добавить соревнование</span> : ''
          }
        </div>
      </div>
    )
  }
}

class Stats extends Component {
  state = {
    stats: {},
    statsLoaded: false,
  }

  componentDidMount() {
    if (typeof this.props.activeCalendar !== 'number') return;
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.activeCalendar !== 'number' || this.props.activeCalendar == prevProps.activeCalendar) {
      return false;
    }
    else {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ ...this.state, statsLoaded: false });

    _calendarApi
      .getCalendarStats(this.props.activeCalendar)
      .then(data => {
        this.setState({ ...this.state, statsLoaded: true, stats: data })
      });
  }

  render() {

    const content = () => (
      <div>
        <StatsRow text="Сумма к выводу" result={this.state.stats.balance} type='deduce' currency="руб." />
        <StatsRow text="Создано соревнований" result={this.state.stats.competition_count} type="competitions" />
        <StatsRow text="Собрано заявок" result={this.state.stats.app_count} />
        <StatsRow text="Собрано взносов" result={this.state.stats.payments} currency="руб." />
      </div>
    );

    const { statsLoaded } = this.state;

    // поправить result для суммы к выводу
    return (
      <div className="wrap-inner">
        <div id="stats" className="stats">
          {
            !statsLoaded ? <LoadingBlock /> : content()
          }
          <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}

export default Stats;
