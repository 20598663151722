import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  clearStorage(e){

    e.preventDefault();
    localStorage.clear();

  }

  navigate(){

    this.props.router.push('/');

  }

  render() {

    const { role } = this.props;
    const adminHeader = (<div className='header__inner-block_admin-links'>
            <Link to='/payments' className='pseudoLink' >закрытие переводов</Link>
            <Link to='/iwannaplay'> не хочу всего вот этого вот!</Link>
            <Link to='/purchases' className='pseudoLink' >закрытие покупок</Link>
          </div>);

    return (
      <div className='header'>
         <div className='header__link-block_wrapper'>
           <div className='header__inner-block header-title' onClick={this.navigate.bind(this)}>
             <span>Спорт&nbsp;</span>
             <span>вокруг.</span>
           </div>
           <div className='header__inner-block nav-block'>
             {role === 'admin' ? adminHeader  : null}
           </div>
           <div className='header__inner-block'>

             { role === 'admin' ? <Link to="#" className='pseudoLink' onClick={this.clearStorage.bind(this)}>завершить сеанс</Link> : null}
           </div>


         </div>
         <div className='header__stripes'>
           <hr/>
           <hr/>
           <hr/>
           <hr/>
         </div>

         <div className='header__bottom'>
           <div className='header__bottom-decor' />

         </div>
      </div>
    );
  }

}

//export default withRouter(Header);
export default Header;
