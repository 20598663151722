import React, { Component } from 'react';
import s from './actinization.less';
import a from './auxiliary';

import _actinizationApi from '../../services/actinizationApi';


class ActinizationPageTable extends Component {
  state = {
    tableData: [],
  }

  componentDidMount() {

    _actinizationApi.getActData()
      .then(result => {
        this.setState({ ...this.state, tableData: result });
      });
  }


  render() {

    let { tableData } = this.state;
    let sum = 0;

    let rows = [];

    if (tableData && tableData.length) {

      tableData.forEach(o => {
        rows.push(<TableRow item={o} key={o.id} />)
        sum += o.document_sum;
      })

    }

    return (
      <div >

        <div className='col-md-6 col-md-offset-6'>
          <div className='text-right'>Всего заказов на сумму: <span>{a.splitDigits(sum)}&#8381;</span></div>
          <div className='text-right'>закрыть покупки</div>
        </div>


        <table className='table'>
          <thead>
            <tr>
              <td ></td>
              <td>Получатель</td>
              <td>Сумма</td>
              <td>описание товара или услуги</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>

      </div>
    )
  }

}

class TableRow extends Component {

  render() {

    let { item } = this.props;

    return (

      <tr>

        <td className={`myTooltip ${item.status}`} data-tooltip={a.paymentTooltip[item.status]}></td>
        <td>{item.receiver}</td>
        <td>{a.splitDigits(item.document_sum)}</td>
        <td >{item.description}</td>
        <td>{a.convertDate(item.created_date)}</td>

      </tr>


    )
  }



}

export default ActinizationPageTable;
