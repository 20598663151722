import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';



const monthRus = {
  1: 'января',
  2: 'февраля',
  3: 'марта',
  4: 'апреля',
  5: 'мая',
  6: 'июня',
  7: 'июля',
  8: 'августа',
  9: 'сентября',
  10: 'октября',
  11: 'ноября',
  12: 'декабря',
  '': '',
}



const splitDigits = (num) => {
  return typeof num !=='undefined'?num.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '):0;
}


const paymentTooltip = {
    onwaiting:  'на рассмотрении',
    rejected:   'отклонён',
    success:    'исполнен',
}



const convertDate = (d) => {


    let date = new Date(d);
    if(d && isNaN(date) && typeof d == 'string'){
      let t = d.match(/(\d{4})-(\d{2})-(\d{2})(?:\s|T)(\d{2}):(\d{2}):(\d{2})/i);
      date = new Date(t[1],+t[2]-1,t[3],t[4],t[5],t[6]);
    }

    let day     = date.getDate().toString();
    let month   = date.getMonth()+1;
    month = month.toString();
    let year    = date.getFullYear().toString();

    return (day.length < 2? '0'+day : day) + ' ' +monthRus[month] + ' ' + year + ' г.';
}



export default {

  splitDigits: splitDigits,
  convertDate: convertDate,
  paymentTooltip: paymentTooltip,
};
