import React, { Component } from 'react';

class ApplicationError extends Component {

  render() {
    return (
      <div className='server-error_wrapper'>
        <div className='server-error'>
            <h2>I'm sorry!</h2>
            <p>Неизвестная ошибка приложения</p>
            <p>Пожалуйста, попробуйте обновить страницу. Если это не помогает - обратитесь к администратору сайта</p>
        </div>
      </div>
    );
  }

}

export default ApplicationError;
