import {
  SET_PAYMENT_SUM,
  SET_PAYMENT_TABLE,
  SET_PAYMENT_POSITION,
  UNSET_PAYMENT_TABLE,
  TOGGLE_CHECKBOXES
} from '../constants/Payments';
// import cookie from 'react-cookie';
//
// export function setPaymentTable() {
//
// }


export function setPaymentTable(value) {

  return	dispatch	=>	{
    dispatch({
        type:	SET_PAYMENT_TABLE,
        payload:	value
    })

    dispatch(setAvailableSum())
  }
}

export function toggleCheckboxes(value) {

  return	dispatch	=>	{
    dispatch({
        type:	TOGGLE_CHECKBOXES,
        payload:	value
    })

    dispatch(setAvailableSum())
  }
}

export function setAvailableSum() {

  return	(dispatch)	=>	{
    dispatch({
        type:	SET_PAYMENT_SUM,

    })
  }
}
