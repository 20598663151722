import React, { Component } from 'react';
import { RIEInput } from 'riek';
import _ from 'underscore';
import aux from '../Auxiliary/auxiliary.js';
import moment from 'moment';

import _withdrawApi from '../../services/withdrawApi.js';
import _contractsApi from '../../services/contractsApi.js';

import classNames from 'class-names';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as paymentActions from '../../actions/paymentActions';

import StateButton from '../Auxiliary/StateButton.js';
import LoadingBlock from '../Auxiliary/LoadingBlock';
import ApplicationError from '../Auxiliary/AppError';


class PaymentTable extends Component {
  state = {
    loading: false,
    processError: false
  }

  makeRowData(item) {
    return {
      id: item.id,
      isChecked: true,
      document_sum: item.document_sum,
      competitions: item.competitions,
      date: aux.convertDate(item.created_date),
      admin_date: item.admin_date ? aux.convertDate(item.admin_date) : '-',
      status: item.status,
      contract: item.contract,
      document_num: item.document_num,
    };
  }

  componentDidMount() {
    this.setPaymentTable();
  }

  componentDidUpdate(prevProps) {
    if (this.props.withRejected !== prevProps.withRejected) {
      this.setPaymentTable();
    }
  }

  setPaymentTable() {
    const { actions } = this.props;

    // let paymentTable = [];

    this.setState({ ...this.state, loading: true });
    const payStatus = this.props.withRejected ? 'with-rejected' : 'standart';
    _withdrawApi.getWithdrawalPayments(payStatus)
      .then((paymentTable) => {
        this.setState({ ...this.state, loading: false });
        actions.setPaymentTable(paymentTable);
      })
      .catch(err => {
        console.error(err);
        this.setState({ ...this.state, processError: true, loading: false });
      });
  }

  rowCheckHandler(name, value) {
    const { paymentTable, actions } = this.props;

    paymentTable[name].isChecked = value;
    actions.setPaymentTable(paymentTable);
  }

  selectAllHandler = (e) => {
    const { actions, paymentTable } = this.props;

    actions.toggleCheckboxes(e.target.checked);
  }

  render() {
    let rows = [];

    const { paymentTable, admin_uid } = this.props;

    return (
      <div>
        {this.state.processError && <ApplicationError />}
        {this.state.loading && !this.state.processError && <LoadingBlock />}
        {!this.state.loading && !this.state.processError &&
          <table className="commonTable">
            <thead>
              <tr>
                <td></td>
                <td>получатель</td>
                <td>договор</td>
                <td>сумма</td>
                <td>заявка сформирована</td>
                <td>заявка выполнена</td>
                <td><input type='checkbox' onClick={this.selectAllHandler} defaultChecked='true' /></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {paymentTable.map(payment => {
                return <TableRow item={this.makeRowData(payment)} key={payment.id} onCheck={this.rowCheckHandler.bind(this)} admin_uid={admin_uid} />
              })}
            </tbody>
          </table>
        }
      </div>
    )
  }
}

class TableRow extends Component {
  state = {
    contractInfoShow: true,
    competitionInfoShow: true,
    // status: this.props.item.status,
    // date: this.props.item.date,
    // admin_date: this.props.item.admin_date,
    item: this.props.item,
  }

  checkHandler = (e) => {
    let { onCheck } = this.props;

    onCheck(e.target.name, e.target.checked)
  }

  contractInfoHandler = (e) => {
    this.setState({ ...this.state, contractInfoShow: !this.state.contractInfoShow })
  }

  competitionInfoHandler = (e) => {
    this.setState({ ...this.state, competitionInfoShow: !this.state.competitionInfoShow })
  }

  changePaymentStatus(status) {
    //const { item, admin_uid } = this.props;
    const { admin_uid } = this.props;
    const item = { ...this.state.item, status: status };
    const data = {
      admin_uid: admin_uid,
    }
    this.setState({ item });

    _withdrawApi.updateWithdrawOrder(status, item.id, data)
      .then(result => {
        // this.setState({status: status})
        this.setState({ item })
      })
  }

  updateDocDate = (data) => {
    // const { item } = this.props;
    const item = { ...this.state.item };

    _withdrawApi.updateWithdrawOrderData(item.id, data)
      .then(result => {
        const newstate = {};
        if (data.date) item.date = data.date;
        if (data.admin_date) item.admin_date = data.admin_date;
        this.setState({ item });
      })
  }

  render() {
    // const { item , admin_uid } = this.props;
    const { admin_uid } = this.props;

    // const { contractInfoShow, competitionInfoShow, status } = this.state;
    const { contractInfoShow, competitionInfoShow, item } = this.state;
    // <td><span>{item.date} </span></td>
    // <td><span>{item.admin_date} </span></td>
    return (
      <tr>
        <td className={`myTooltip ${item.status}`} data-tooltip={aux.paymentTooltip[item.status]} name={item.id}></td>
        <td>{item.contract.they_companyshortname || 'название'}</td>
        <td onClick={this.contractInfoHandler}><span className='clickableSpan'>{item.contract.contract_num}</span> <ContractInfo item={item.contract} hidden={classNames({ hidden: contractInfoShow })} /></td>
        <td onClick={this.competitionInfoHandler}><span className='clickableSpan'>{item.document_sum}</span> <CompetitionInfo item={item.competitions} hidden={classNames({ hidden: competitionInfoShow })} /> </td>
        <td>
          <InlineDateEdit item={item} field='date' update={this.updateDocDate} />
        </td>
        <td>
          <InlineDateEdit item={item} field='admin_date' update={this.updateDocDate} />
        </td>
        <td>
          <input className='isChecked' type='checkbox' name={item.document_num} onChange={this.checkHandler} checked={item.isChecked} />
        </td>
        <td><StateButton currentStatus={item.status} onUpdate={this.changePaymentStatus.bind(this)} /></td>
      </tr>
    )
  }
}

class InlineDateEdit extends Component {
  updateDate = (data) => {
    this.props.update(data);
  }

  checkIsDate(value) {
    let m = moment(value, 'DD.MM.YYYY');
    return m.isValid();
  }

  render() {
    const { item, field } = this.props;
    return (
      <RIEInput
        value={item[field]}
        change={this.updateDate}
        propName={field}
        validate={this.checkIsDate}
        classInvalid='inline-date-invalid'
        classEditing='inline-date-edit'
        shouldRemainWhileInvalid={true}
      />
    );
  }
}

class ContractInfo extends Component {
  render() {
    const { item, hidden } = this.props;

    return (
      <div className={` ${hidden} nesting_info`} >
        <div>договор № {item.contract_num} от {aux.convertDate(item.contract_date)}</div>
        <div>р/с {item.they_account}</div>
        <div>в {item.they_bankname} </div>
        <div>БИК {item.they_bik}</div>
        <div>к/с {item.they_coraccount}</div>
      </div>
    )
  }
}

class CompetitionInfo extends Component {
  render() {
    const { item, hidden } = this.props;

    return (
      <div className={` ${hidden} nesting_info`}>{
        item.map(o => {
          return <div key={o.id}> {o.withdrawal_sum} {o.competition_title}</div>
        })
      }</div>
    )
  }
}

function mapStateToProps(state) {
  return {
    paymentSum: state.payment.paymentSum,
    paymentTable: state.payment.paymentTable,
    admin_uid: state.auth.user.user_uid,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(paymentActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable)
