import React, { Component } from 'react';

import _contractsApi from '../../services/contractsApi.js'

import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import BackToMain from '../Auxiliary/BackToMain.js';
import aux from '../Auxiliary/auxiliary.js';

import { connect } from 'react-redux';

class ExistingContract extends Component {

  state = {
    contract: [],
  }

  componentDidUpdate(prevProps) {

    const { calendar, location } = this.props;

    const contract = location.pathname.split('/')[2];
    const prevContract = prevProps.location.pathname.split('/')[2];

    if (contract == prevContract || calendar == prevProps.calendar) return false;

    _contractsApi.getContractByCalendarIdAndContractNumber(calendar, contract)
      .then(
        result => {
          this.setState({
            contract: result.data[0],
            fullContract: false,
          });
        }
      )
  }

  render() {

    const { contract } = this.state;

    return (
      <div>
        <div className="head">
          <div className="contract-info">
            <div className="contract-info-first">
              <span className="contract-name">
                <span className="contract-status"></span>
                Агентский договор
              </span>
              <span className="contract-id">№{contract.contract_num}</span>
              <span className="contract-download">Скачать</span>
            </div>
            <div className="contract-info-second">
              <span className="city">г.Москва</span>
              <span className="date">{aux.convertDate(contract.contract_date)}</span>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="agreements">
          <div className={this.state.fullContract ? 'agreements-text' : 'agreements-text short'}>
            ____________________________________________________, в дальнейшем «Принципал», с одной стороны,
            Общество с ограниченной ответственностью «Спорт вокруг.СиЭрЭм» в лице Генерального директора
            Васильева М.В., действующего на основании Устава, именуемое в дальнейшем «Агент», с другой
            стороны», именуемые в дальнейшем «Стороны», заключили настоящий договор, в дальнейшем «Договор»,
            о нижеследующем:…
          </div>
          <div onClick={this.updateContractVisibility} className="agreements-show-full-text">
            {this.state.fullContract ? 'скрыть' : 'Показать полностью'}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="alertMessage alert alert-success text-center hideIt" >
          <strong> Данные успешно сохранены!</strong>
        </div>
        <div className="details">
          <h1>Реквизиты сторон</h1>
          <div className="details-left">
            <div className="organization-info">
              <div className="organization-text">
                <span className="info">
                  Общество с ограниченной ответственностью <br />
                  «Спорт вокруг. СиЭрЭм» (ООО «Спорт вокруг.
                  СиЭрЭм»)
                </span>

                юридический адрес: {contract.we_legaladdress}<br />
                почтовый адрес: {contract.we_postaddress}<br />
                ИНН {contract.we_inn}<br />
                КПП {contract.we_kpp}<br />
                р/с {contract.we_account}<br />
                в {contract.we_bankname}<br />
                БИК {contract.we_bik}<br />
                к/с {contract.we_coraccount}
              </div>
              <div className="clearfix"></div>
              <div className="organization-owner">
                генеральный директор
                Васильев М.В.
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="details-right">

            <div className='form-title' > Юридиеское лицо </div>

            <div className="form">

              <ContractForm contract={contract} />
            </div>

          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }

}


class ContractForm extends Component {
  render() {
    const { contract } = this.props;

    return (
      <div id='form' className='form-wrapper'>


        <div key='they_companyname' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_companyname'
            value={contract.they_companyname}
            placeholder='название компании полное' />

        </div>
        <div key='they_companyshortname' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_companyshortname'
            value={contract.they_companyshortname}
            placeholder='название компании краткое' />

        </div>
        <div key='they_legaladdress' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_legaladdress'
            value={contract.they_legaladdress}
            placeholder='юридический адрес' />

        </div>
        <div key='they_postaddress' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_postaddress'
            value={contract.they_postaddress}
            placeholder='почтовый адрес' />

        </div>

        <div key='they_inn' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_inn'
            value={contract.they_inn}
            placeholder='ИНН' />
        </div>

        <div key='they_kpp' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_kpp'
            value={contract.they_kpp}
            placeholder='КПП' />

        </div>
        <div key='they_account' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_account'
            value={contract.they_account}
            placeholder='р/с' />
        </div>
        <div key='they_bik' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_bik'
            value={contract.they_bik}
            placeholder='БИК' />

        </div>
        <div key='they_bankname' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_bankname'
            value={contract.they_bankname}
            placeholder='банк' />
        </div>
        <div className="form-group" key='they_coraccount'>
          <input type='text'
            readOnly='true'
            name='they_coraccount'
            value={contract.they_coraccount}
            placeholder='к/с' />
        </div>
        <div key='they_phone' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_phone'
            value={contract.they_phone}
            placeholder='контактный телефон' />

        </div>
        <div key='they_signposition' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_signposition'
            value={contract.they_signposition}
            placeholder='должность руководителя' />
        </div>

        <div key='they_fio' className="form-group">
          <input type='text'
            readOnly='true'
            name='they_fio'
            value={contract.they_fio}
            placeholder='ФИО руководителя' />
        </div>

      </div>
    )
  }

}


function mapStateToProps(state) {

  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, null)(ExistingContract);
