import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './source/store/configureStore'
import App from './source/components/App/App.jsx'




const store = configureStore();


ReactDOM.render(
    (
      <Provider store={store} >
        <App />
      </Provider>
    ),
    document.getElementById("root")
);
