import React, {Component} from 'react';
import { Link } from 'react-router-dom';


class  Navigation  extends Component{

    render() {
      const navData = [
              {
                  url: "competitions",
                  img: "competitions.png",
                  title: "Мои соревнования",
                },
             {
                  url: "contracts",
                  img: "contracts.jpg",
                  title: "Мои договоры",
                },
             {
                  url: "history",
                  img: "history.png",
                  title: "История выводов",
                },
            //  {
            //       url: "settings",
            //       img: "settings.png",
            //       title: "Мои настройки",
            //     },
      ];
        return (
            <nav className="lk-navMenu">
                {navData.map( (navRow, i) => {
                  return (<Link key={i} to={`/${navRow.url}`}  >

                            <div className={`${navRow.url+'Img'}`} />
                            <span>{navRow.title}</span>
                          </Link>
                        )
                })}
            </nav>
        )
    }
}

export default Navigation;
//<img src={`/img/${navRow.img}`} />
