import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';

let docflow = () => {

  return {


    getHistoryByCalendar: function (calendar_id) {

      return ensure_auth_token()
          .then(function() {
            return axios.get(`/api/v1.0/docflow/${calendar_id}/history/`)
          })
          .then(function (response) {

            if(response.status >= 200 && response.status < 300)
              return(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });

    },

  }

}

export default docflow();
