import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './PaymentHistory.css';
import aux  from '../Auxiliary/auxiliary.js';

// import _withdrawApi from '../../services/withdrawApi.js';
import _docflowApi from '../../services/docflowApi.js';
import _ from 'underscore'


class Table extends Component{
  state = {
    history: [],
    activeCalendar: 0
  }

  _setData(calendar){

    let history = [];


    if(calendar && _.isNumber(calendar)) {

      _docflowApi.getHistoryByCalendar(calendar).then(
        data => {


          history = data.historyData || [];

          history.sort(aux.historySort);

          this.setState({...this.state, activeCalendar: calendar, history: history})
        }
      )

    }
  }


  componentDidMount(){
    const {calendar} = this.props;

    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');
    if(activeCalendar === 0) return false;
    this._setData(activeCalendar);
  }



  componentDidUpdate(prevProps, prevState){
    const {calendar} = this.props;

    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');
    if(activeCalendar === 0 || activeCalendar == prevState.activeCalendar) return false;
    this._setData(activeCalendar);
  }


    render() {

      let {history} = this.state,
          rows      = [];

      history.forEach((o, i) => {

        if(i >= 3 ) return;

        rows.push(<TableRow item={o} key={i} />)
      })

        return (
            <div className="">
                <table className="commonTable">
                    <thead>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            платеж
                        </td>
                        <td>
                            сумма вывода
                        </td>
                        <td>
                            договор
                        </td>

                    </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                </table>



                <aux.More quantity={history.length} title='показать все' link='/history'/>

            </div>
        );
    }
}

class TableRow extends Component {
  render(){

    let {item}  = this.props,
        contract_number = item.contract && item.contract.contract_num ? item.contract.contract_num : 0;

    return(

      <tr>
        <td className={`myTooltip ${item.status}`} data-tooltip={aux.paymentTooltip[item.status]}></td>
        <td>{aux.convertDate(item.created_date)}</td>
        <td>#{item.document_num}</td>
        <td >{aux.splitDigits(item.document_sum)}</td>
        <td>#{contract_number}</td>

      </tr>
    )

  }

}

class PaymentHistory extends Component{
    render() {
        return (
            <div className="wrap-inner">
                <div id="history" className="history">
                    {location.pathname == '/history' ? <NavigationBackToMain /> : ""}
                    <aux.Head img="img/history.png" headTitle="История выводов"/>
                    <Table calendar={this.props.activeCalendar}/>
                </div>
            </div>
        );
    }
}

export default PaymentHistory;
