import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';


var calendars = function () {
    return {

        getCalendars: function () {
          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/calendars`)
                      })
                      .then(function (response) {

                          return(response.data);
                        })
                      .catch(function (error) {
                        console.log(error);
                      });
        },
        getCalendarStats: function (calendar_uuid) {
          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/calendars/${calendar_uuid}/stats/`)
                      })
                      .then(function (response) {

                          return(response.data);
                        })
                      .catch(function (error) {
                        console.log(error);
                      });

        },
        getCalendarsByUserId: function (user_uuid) {
          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/users/${user_uuid}/calendars/`)
                      })
                      .then(function (response) {

                          return(response.data);
                        })
                      .catch(function (error) {
                        console.log(error);
                      });
        },


        getCalendarCompetitions: function (calendar_uuid) {
          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/lk_api/v1.0/calendar_competitions?calendar_uuid=${calendar_uuid}`)
                      })
                      .then(function (response) {

                          return(response.data);
                        })
                      .catch(function (error) {
                        console.log(error);
                      });
        },

        getCalendarContracts: function (calendar_uuid) {
          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/lk_api/v1.0/calendar_contracts?calendar_uuid=${calendar_uuid}`)
                      })
                      .then(function (response) {

                          return(response.data);
                        })
                      .catch(function (error) {
                        console.log(error);
                      });
        }

    };
};

export default calendars();
