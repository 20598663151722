import jQuery from 'jquery';

import querystring from 'querystring';
import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';

var _req = jQuery.ajax;


var contracts = function () {
    return {
        getContracts: function () {
            return _req({
                url: `/api/v1.0/competitions`,
                method: 'GET'
            })
        },
        getContractsByCalendarId (calendar_uuid, type) {

          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/calendars/${calendar_uuid}/${type}/contracts/`)
                      })
                      .then( result => {
                        return result.data
                      })
        },
        getContractsByObjectId (data) {

          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/contracts/`, querystring.stringify(data))
                      })
                      .then( result => {
                        return (result);
                      })
        },

        getContractByCalendarIdAndContractNumber (calendar_id, contract_num) {

          return ensure_auth_token()
                      .then(function() {
                        return axios.get(`/api/v1.0/contracts/${calendar_id}/${contract_num}/find/`)
                      })
                      .then( result => {
                        return (result);
                      })
        },

        createContract: function (data) {
            return _req({
                url: `/api/v1.0/contracts/create/`,
                method: 'POST',
                data: data
            })
        }



    };
};

export default contracts();
