import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RIEInput } from 'riek';
import moment from 'moment';
import _ from 'underscore';

import aux from '../Auxiliary/auxiliary.js';
import _actinizationApi from '../../services/actinizationApi.js'

import StateButton from '../Auxiliary/StateButton.js';
import LoadingBlock from '../Auxiliary/LoadingBlock';
import ApplicationError from '../Auxiliary/AppError';

class ActinizationClosureTable extends Component {
  state = {
    purchases: [],
    loading: true,
    processError: false
  }

  makeRowData(item) {
    return {
      ...item,
      created_date: aux.convertDate(item.created_date),
      admin_date: item.admin_date ? aux.convertDate(item.admin_date) : '-',
    };
  }

  componentDidMount() {
    let purchases = [];
    const { role } = this.props;

    role !== 'admin' ? this.props.denied(true) : null;

    _actinizationApi.getPurchases('waiting')
      .then(data => {
        // сперва идут ожидающие заявки
        const onwaiting = _.reduce(
          data,
          (memo, item) => {
            if (item.status === 'waiting') {
              memo.push(this.makeRowData(item));
            }
            return memo;
          },
          []);

        // .. затем все остальное
        const other = _.reduce(
          data,
          (memo, item) => {
            if (item.status !== 'waiting') {
              memo.push(this.makeRowData(item));
            }
            return memo;
          },
          []);

        return [...onwaiting, ...other];
      })
      .then(purchases => {
        return (purchases && purchases.length) ? this.setState({ ...this.state, loading: false, purchases }) : false;
      })
      .catch(err => {
        this.setState({ ...this.state, loading: false, processError: true });
        console.error(err);
      });
  }

  render() {
    const { admin_uid } = this.props;
    let { purchases } = this.state;

    return (
      <div className=''>
        {this.state.processError && <ApplicationError />}
        {this.state.loading && !this.state.processError && <LoadingBlock />}
        {!this.state.loading && !this.state.processError &&
          <table className='commonTable'>
            <thead>
              <tr>
                <td></td>
                <td>получатель</td>
                <td>соревнование</td>
                <td>сумма</td>
                <td>заявка сформирована</td>
                <td>заявка выполнена</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {purchases && purchases.length ? purchases.map((row, i) => {

                return <TableRow item={row} key={i} admin_uid={admin_uid} />

              }) : null}
            </tbody>
          </table>
        }
      </div>
    )
  }
}

class TableRow extends Component {

  state = {
    status: this.props.item.status,
    item: this.props.item
  }


  changePaymentStatus(status) {
    // const { item, admin_uid } = this.props;
    const { admin_uid } = this.props;
    const { item } = this.state;

    let data = {
      admin_uid: admin_uid,
    }

    _actinizationApi.updatePurchase(status, item.id, data)
      .then(result => {
        this.setState({ status: status })
      })
  }

  updateDocDate = (data) => {
    const item = { ...this.state.item };

    _actinizationApi.updateActData(item.id, data)
      .then(result => {
        if (data.created_date) item.created_date = data.created_date;
        if (data.admin_date) item.admin_date = data.admin_date;
        if (data.description) item.description = data.description;
        this.setState({ item });
      })
  }

  render() {
    const { admin_uid } = this.props;
    const { status, item } = this.state;

    return (
      <tr>
        <td className={`myTooltip ${status}`} data-tooltip={aux.paymentTooltip[status]} name={item.id}></td>
        <td>
          <div>{item.receiver}</div>
          <div>
            <small><InlineDateEdit item={item} field='description' update={this.updateDocDate} classInvalid='inline-text-invalid' classEditing='inline-text-edit' validateAsDate={false} /></small>
          </div>
        </td>
        <td>{item.competition_id}</td>
        <td>{item.document_sum}</td>
        <td>
          <InlineDateEdit item={item} field='created_date' update={this.updateDocDate} validateAsDate={true} />
        </td>
        <td>
          <InlineDateEdit item={item} field='admin_date' update={this.updateDocDate} validateAsDate={true} />
        </td>
        <td ><StateButton currentStatus={status} onUpdate={this.changePaymentStatus.bind(this)} /></td>
      </tr>
    )
  }
}

class InlineDateEdit extends Component {

  updateData = (data) => {
    this.props.update(data);
  }

  checkIsDate(value) {
    let m = moment(value, 'DD.MM.YYYY');
    return m.isValid();
  }

  render() {
    const { item, field, classInvalid = 'inline-date-invalid', classEditing = 'inline-date-edit', validateAsDate } = this.props;
    return (
      <RIEInput
        value={item[field]}
        change={this.updateData}
        propName={field}
        validate={validateAsDate ? this.checkIsDate : null}
        classInvalid={classInvalid}
        classEditing={classEditing}
        shouldRemainWhileInvalid={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    admin_uid: state.auth.user.user_uid,
    role: state.auth.user.role,
  }
}

export default connect(mapStateToProps, null)(ActinizationClosureTable)
