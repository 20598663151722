const config = {
    MYSQL_SETTINGS:{
        host: '82.202.247.58',
        user: 'sportdb',
        password: 'XfT6P~5wpKy0O8~%J9yDVqpV',
        database: 'sportvokrug'
    },
    SESSION_LIFETIME:  1000,
    secret: 'sup333rsecReTkEY42__makar!we@<3_YOU!!sp0rTv0kr!_@#@!@#&u&___g!',
    code: 'zpR5efQ-4d5ZLtWD1',
    PERMANENT_TOKEN: 'S9Z8EGGTVVtLggFTizCsMtwOJnRhjaQ2BMUQhcY',

    HOST: 'localhost',
    PORT: 4040,

    APIHOST: 'www.sportvokrug.ru',
    LK_SITE: 'https://lk.sportvokrug.ru',

    CORS_ALLOW: [
      'https://beta.sosportom.ru',
      'https://release.sosportom.ru',
      'https://www.sportvokrug.ru',
      'https://sportvokrug.ru',
      'https://www.sportvokrug.com',
      'https://sportvokrug.com',
    ],
};
module.exports = config;
