import React, { Component } from 'react';
import Header from '../Header/Header.jsx'
import Footer from '../Footer/Footer.jsx'
import Calendars from '../Calendars/Calendars.jsx'
import Stats from '../Stats/Stats.jsx'
import Competitions from '../Competitions/Competitions.jsx'
import Contracts from '../Contracts/Contracts.jsx'
import PaymentHistory from '../PaymentHistory/PaymentHistory.jsx'
import Settings from '../Settings/Settings.jsx'
import _calendarApi from '../../services/calendarsApi.js'
// import _userApi from '../../services/userApi.js'
import cookie from 'react-cookie';
import Navigation from '../Navigation/Navigation.jsx'

//import _ from 'underscore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CalendarActions from '../../actions/CalendarActions';
import * as authActions from '../../actions/authActions';

import './Home.css';

class Home extends Component {

  state = {
    calendars: [],
  };

  _setData(user_uuid) {



    _calendarApi.getCalendarsByUserId(user_uuid).then(

      data => {

        if (typeof data !== 'string') {

          this.setState({
            calendars: data,
          })

        } else {
          this.props.router.push('/')
        }

      }
    )
  }

  componentDidMount() {

    const { uinfo } = this.props;

    if (uinfo && uinfo.length > 0) {

      this._setData(this.props.uinfo);

    }
  }


  switchCalendar(activeCalendar) {

    this.props.calendarAction.setCalendar(activeCalendar)
  }

  render() {

    const { calendar, uinfo } = this.props;


    return (
      <div>
        <h1>Личный кабинет</h1>
        <Calendars calendars={this.state.calendars} activeCalendar={calendar} onUpdate={this.switchCalendar.bind(this)} />
        <Navigation />
        <Stats activeCalendar={calendar} uinfo={uinfo} />
        <Competitions activeCalendar={calendar} />
        <Contracts activeCalendar={calendar} />
        <PaymentHistory activeCalendar={calendar} />

      </div>
    );
  }
}
function mapStateToProps(state) {

  return {
    calendar: state.calendar,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    calendarAction: bindActionCreators(CalendarActions, dispatch),
    auth: bindActionCreators(authActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

//                <Settings/>
