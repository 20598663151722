import React, {Component} from 'react';

//
import ActinizationPageTitle       from './ActinizationPageTitle';
import ActinizationPageTable       from './ActinizationPageTable';
import ActinizationPageInputGroup  from './ActinizationPageInputGroup';

class ActinizationPage extends Component {
  state = {
    up: 0,
  }

  updatePage(val){
    if(val){

      this.setState({up: Math.random()})

    }
  }

  render(){

    return(
      <div className='container'>

          <ActinizationPageTitle />
          <ActinizationPageTable updater={this.state.up}/>
          <ActinizationPageInputGroup onUpdate={this.updatePage.bind(this)}/>

      </div>
    )
  }

}

export default ActinizationPage;
// */
