//import jQuery from 'jquery';
//var _req = jQuery.ajax;
import axios from 'axios';
import ensure_auth_token from './ensure_auth_token';


var competitions = function () {
    return {
        getCompetitions: function () {
          return ensure_auth_token()
            .then(function() {
              return axios.get(`/api/v1.0/competitions`)
            })
            .then( (response) => {
                return(response.data);
            })
            .catch( (error) => {
              console.error(error);
            });
        },

        // calendar_id - id календаря по которому идёт отбор
        // type          - тип возвращаемых данных. wP - withdrawalPage, для странички вывода, cT - competitionsTable, таблица с соревнованиями на главной
        getCompetitions4TableByCompetitionId(calendar_id) {

          return ensure_auth_token()
                    .then(function() {
                      return axios.get(`/api/v1.0/calendars/${calendar_id}/fulltable/`)
                    })
                    .then( (response) => {
                        return(response.data);
                    })
                    .catch( (error) => {
                      console.error(error);
                    });

        },

        // data может содержать:
        // offset, limit
        getCompetitionsListByCalendarId: function (calendar_id, data) {
          return ensure_auth_token()
            .then(function() {
              return axios.get(`/api/v1.0/calendars/${calendar_id}/competitions/`, { params: data })
            })
            .then( (response) => {
                return(response.data);
            })
            .catch( (error) => {
              console.error(error);
            });
        },

        getWithdrawalsListByCalendarId: function (calendar_id, data) {
          return ensure_auth_token()
            .then(function() {
              return axios.get(`/api/v1.0/calendars/${calendar_id}/withdrawals/`, { params: data })
            })
            .then( (response) => {
                return(response.data);
            })
            .catch( (error) => {
              console.error(error);
            });
        },

    };
};

export default competitions();
