import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Applications.css';
import './Applications.css';

class Application extends Component {
  render() {
    return (
      <div className="application">
        <div className="inner">
          <div className="header">{this.props.heading}</div>
          {
            this.props.type == 'table' ?
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>оборот</th>
                      <th>зараб</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              : ""
          }
          {
            this.props.type == 'cost-list' ?
              <div className="cost-list">
                <div className="application-info">
                  <div className="application-info-count">Заявок: 2</div>
                  <div className="application-info-total">48 000</div>
                  <div className="clearfix"></div>
                </div>
                <div className="items">
                  <div className="item-name">название соревнования</div>
                  <div className="item-total">40 000</div>
                </div>
              </div>
              : ""
          }
          {
            this.props.type == 'list' ?
              <div className="list">
                <div className="application-info">
                  <span>Организации: 1</span>
                </div>
                <div className="items">
                  <div className="item-name">название организации</div>
                </div>
              </div>
              : ""
          }
          <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}

class Applications extends Component {
  render() {
    return (
      <div className="wrap-inner">
        <div className="applications">
          <Application heading="Направление в банк" type="table" />
          <Application heading="Модерация соревнований" type="table" />
          <Application heading="Новые заявки на вывод" type="cost-list" />
          <Application heading="Новые соревнования" type="list" />
          <Application heading="Новые организации" type="list" />
          <Application heading="Новые договоры" type="list" />
          <Application heading="Новые покупки" type="cost-list" />
          <div className="clearfix"></div>
        </div>
      </div>
    )
  }
}

export default Applications;
