import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './Settings.css';
import aux from '../Auxiliary/auxiliary.js';


// class Head extends React.Component{
//     render() {
//         return (
//             <div className="heading_">
//                 <img className="nav-img" src="img/settings.png"/>
//                 <div className="nav-text">
//                     <b>Мои настройки</b>
//                 </div>
//                 <div className="clearfix"></div>
//             </div>
//         );
//     }
// }

class NavigationBackToMain extends Component {
  render() {
    return (
      <div className="nav-back">
        <Link to="/">вернуться в личный кабинет</Link>
      </div>
    )
  }
}

class Settings extends Component {
  state = {
    AssistantPersonalVisible: false,
    ResponsivePersonalVisible: false,
  }
  setVisibleResponsePersonal() {
    this.setState({
      ResponsivePersonalVisible: true
    })
  }
  setInvisibleResponsePersonal() {
    this.setState({
      ResponsivePersonalVisible: false
    })
  }
  setVisibleAssistantPersonal() {
    this.setState({
      AssistantPersonalVisible: true
    })
  }
  setInvisibleAssistantPersonal() {
    this.setState({
      AssistantPersonalVisible: false
    })
  }


  render() {
    return (
      <div className="wrap-inner">
        <div id="settings" className="settings">
          {location.pathname == '/settings' ? <NavigationBackToMain /> : ""}
          <aux.Head img="img/settings.png" headTitle="Мои настройки" />

          <div className="clearfix" ></div>
          <div className="calendar-name">
            Название календаря:
          </div>
          <div className="organizer-name">
            Организатор
          </div>
          <div className="organizer-name-edit">Редактировать</div>
          <div className="logo">
            <div className="no-logo">
              <span>логотип не загружен</span>
            </div>
            <span className="load-logo">загрузить логотип</span>
          </div>
          <div className="logo-info">
            Ваш логотип будет отображаться на странице соревнования и в календаре
          </div>
          <div className="clearfix"></div>

          <div className="post-calendar">
            Разместить календарь на другом сайте
          </div>

          <div className="personal">
            <div className="responsible">
              <div className="info">
                <span className="info-text">Ответственные за календарь</span>
                <div className="tooltip">
                  <img className="info-icon" src="img/info.png" />
                  <span className="tooltiptext">Бабл</span>
                </div>
              </div>
              <div className="current">
                <span className="index">1.</span>
                Вы (<span className="name">Александр Паньков</span>)
              </div>
              <div className="show-actions">
                {
                  !this.state.ResponsivePersonalVisible ?
                    <div>
                      <img src="img/add.png" />
                      <span onClick={this.setVisibleResponsePersonal}>
                        Добавить ответственного
                      </span>
                    </div>
                    : null
                }
              </div>
              {
                this.state.ResponsivePersonalVisible ?
                  <div className="add">
                    <input className="add-input" type="text"
                      placeholder="укажите фамилию, имя, почту или ссылку на профиль" />
                    <div className="actions">
                      <div className="submit">
                        <span className="submit-text">Добавить</span>
                      </div>
                      <div onClick={this.setInvisibleResponsePersonal} className="cancel">
                        отмена
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  : null
              }
              <div className="clearfix"></div>
            </div>
            <div className="assistants">
              <div className="info">
                <span className="info-text">Помощники</span>
                <div className="tooltip">
                  <img className="info-icon" src="img/info.png" />
                  <span className="tooltiptext">Бабл</span>
                </div>
              </div>
              <div className="current">
                <span className="index">1.</span>
                Вы (<span className="name">Александр Паньков</span>)
              </div>
              <div className="show-actions">
                {
                  !this.state.AssistantPersonalVisible ?
                    <div>
                      <img src="img/add.png" />
                      <span onClick={this.setVisibleAssistantPersonal}>
                        Добавить помощника
                      </span>
                    </div>
                    : null
                }
              </div>
              {
                this.state.AssistantPersonalVisible ?
                  <div className="add">
                    <input className="add-input" type="text"
                      placeholder="укажите фамилию, имя, почту или ссылку на профиль" />
                    <div className="actions">
                      <div className="submit">
                        <span className="submit-text">Добавить</span>
                      </div>
                      <div onClick={this.setInvisibleAssistantPersonal} className="cancel">
                        отмена
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  : null
              }
              <div className="clearfix"></div>
            </div>
          </div>

          <div className="payments-name">
            <div>
              Названия платежей
              <div className="tooltip">
                <img className="info-icon" src="img/info.png" />
                <span className="tooltiptext">Бабл</span>
              </div>
            </div>
            <div className="input">
              <select>
                <option>стартовые взносы</option>
                <option>стартовые взносы</option>
                <option>стартовые взносы</option>
                <option>стартовые взносы</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Settings;
