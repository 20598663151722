import axios from 'axios';

const ensure_auth_token = () => {
  if (axios.defaults.headers.common['Authorization']) return Promise.resolve();
  return new Promise(resolve => {
    setTimeout(() => {
      ensure_auth_token();
      resolve();
    }, 100);
  });

}


export default ensure_auth_token;

