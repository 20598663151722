import React, {Component} from 'react';
import { Link } from 'react-router-dom';

export default class NotFoundPage extends Component {
    render() {
        return (
            <div className="404">
                <h1>404</h1>
                <h2>Page not found!</h2>
                <p>
                    <Link to="/">На главную страницу</Link>
                </p>
            </div>
        );
    }
}
