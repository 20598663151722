import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Head extends Component {
  render() {

    return (
      <div className="block_heading">
        <img className="block_heading-img" src={`${this.props.img}`} />
        <div className="block_heading-text">
          <b>{this.props.headTitle}</b>
          {(this.props.url && this.props.urlTitle) ?
            <span>
              <Link to={`${this.props.url}`} > {this.props.urlTitle} </Link>
            </span>
            : null
          }

        </div>
      </div>
    )
  }
}

class More extends Component {
  render() {
    return (
      <Link to={this.props.link} className="more" > {this.props.title}&nbsp;({this.props.quantity}) </Link>

    )
  }

}
const monthRus = {
  1: 'января',
  2: 'февраля',
  3: 'марта',
  4: 'апреля',
  5: 'мая',
  6: 'июня',
  7: 'июля',
  8: 'августа',
  9: 'сентября',
  10: 'октября',
  11: 'ноября',
  12: 'декабря',
  '': '',
}

const statusButtonRus = {
  success: 'принята',
  rejected: 'отклонена',
  onwaiting: 'на утверждении'
}


const splitDigits = (num) => {
  return !isNaN("" + num) ? num.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
}

const strToNum = (str) => {
  return isNaN("" + str) ? 0 : +str;
}


const paymentTooltip = {
  onwaiting: 'на рассмотрении',
  rejected: 'отклонён',
  success: 'исполнен',
}

const historySort = (a, b) => {
  if (a.created_date > b.created_date)
    return -1;
  if (a.created_date < b.created_date)
    return 1;
  return 0;
}

const idSort = (a, b) => {
  if (a.competition_id > b.competition_id)
    return -1;
  if (a.competition_id < b.competition_id)
    return 1;
  return 0;
}


const contractSort = (a, b) => {
  if (a.contract_date > b.contract_date)
    return -1;
  if (a.contract_date < b.contract_date)
    return 1;
  return 0;
}

const unique = (arr) => {
  let obj = {};

  for (let i = 0; i < arr.length; i++) {
    let str = arr[i];
    obj[str] = true; // запомнить строку в виде свойства объекта
  }

  return Object.keys(obj); // или собрать ключи перебором для IE8-
}


const convertDate = (d) => {


  let date = new Date(d);
  if (d && isNaN(date) && typeof d == 'string') {
    let t = d.match(/(\d{4})-(\d{2})-(\d{2})(?:\s|T)(\d{2}):(\d{2}):(\d{2})/i);
    date = new Date(t[1], +t[2] - 1, t[3], t[4], t[5], t[6]);
  }

  let day = date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month.toString();
  let year = date.getFullYear().toString();

  return (day.length < 2 ? '0' + day : +day) + '.' + (month.length < 2 ? '0' + month : month) + '.' + year;
}

const cutString = (str) => {

  return str.slice(0, 25) + '...' + str.slice(-12);

}

export default {
  Head: Head,
  More: More,
  monthRus: monthRus,
  statusButtonRus: statusButtonRus,
  splitDigits: splitDigits,
  strToNum: strToNum,
  convertDate: convertDate,
  historySort: historySort,
  idSort: idSort,
  contractSort: contractSort,
  paymentTooltip: paymentTooltip,
  cutString: cutString,
};

