import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import aux from '../Auxiliary/auxiliary';

const config = require('../../../../server/.config.js');

const dateConverter = (dateStart, dateEnd) => {

  const _dateStart = new Date(dateStart),
    _dateEnd = new Date(dateEnd),
    _dateStartDay = _dateStart.getDate().toString().length < 2 ? '0' + _dateStart.getDate().toString() : _dateStart.getDate().toString(),
    _dateEndDay = _dateEnd.getDate().toString().length < 2 ? '0' + _dateEnd.getDate().toString() : _dateEnd.getDate().toString();
  let _dateStartMonth = _dateStart.getMonth() + 1,
    _dateEndMonth = _dateEnd.getMonth() + 1;
  _dateStartMonth = _dateStartMonth.toString().length < 2 ? '0' + _dateStartMonth.toString() : _dateStartMonth.toString();
  _dateEndMonth = _dateEndMonth.toString().length < 2 ? '0' + _dateEndMonth.toString() : _dateEndMonth.toString();


  const _date = (_dateStartDay === _dateEndDay && _dateStartMonth === _dateEndMonth) ? _dateEndDay + '.' + _dateEndMonth + '.' + _dateEnd.getFullYear().toString() : _dateStartDay + '.' + _dateStartMonth + '-' + _dateEndDay + '.' + _dateEndMonth + '.' + _dateEnd.getFullYear().toString();

  return _date;

}

class CompetitionTableRow extends Component {

  render() {
    const tooltipToRus = {
      published: 'опубликовано',
      new: 'создано',
      canceled: 'отказано в публикации'
    }

    const { calendar, item } = this.props;

    return (
      <tr>
        <td className={`myTooltip ${item.status}`} data-tooltip={tooltipToRus[item.status]}></td>
        <td>
          <div className="title">
            <a href={'https://' + config.APIHOST + `/competitions/${item.competition_id}/`} target="_blank" rel="noopener">{`#${item.competition_id} ${item.title}`}</a>
          </div>
          <div>
            <small>{item.city}, </small>
            <small> {dateConverter(item.dt_begin, item.dt_end)}</small>
          </div>
          {calendar != item.calendar_id &&
            <div>
              <small>Соревнование из календаря «{item.calendar_name}»</small>
            </div>
          }
        </td>
        <td> <span>{aux.strToNum(item.app_count)}{aux.strToNum(item.newapp_count) > 0 ? '(' + aux.strToNum(item.newapp_count) + ')' : ''}</span>/<span>{item.participant_count || 0}</span></td>
        <td> {aux.splitDigits(item.budjet_plan)}</td>
        <td> {aux.splitDigits(aux.strToNum(item.pay_system) + aux.strToNum(item.pay_custom))}</td>
        <td> {aux.splitDigits(item.pay_system)}</td>
        <td> {aux.splitDigits(item.withdraw)}
          {
            item.withdraw != 0 ?
              <small className="withdrawLink">
                <Link to={`/withdrawal/${item.competition_id}/`}>вывести</Link>
              </small> : ''
          }
        </td>
      </tr>
    )
  }
}

export default CompetitionTableRow;
