import React, { Component } from 'react';
import _authApi from '../../services/authApi.js'
import { connect } from 'react-redux';

import * as authActions from '../../actions/authActions';
import { bindActionCreators } from 'redux';
import _ from 'underscore';

import { Routes } from '../../routes';

class App extends Component {

  state = {
    uinfo: '',
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, uinfo: props.user_uuid };
  }

  checkTokenAndVerifyUser = () => {
    const { actions } = this.props;
    const token = localStorage.lktoken;

    _authApi.verification()
      .then(res => {
        if (res.data) {
          this.props.actions.setToken(res.data);
        }
        else {
          this.props.actions.loginOnNewWebsite();
        }
      })
  }

  componentDidMount() {
    this.checkTokenAndVerifyUser();
  }


  static getDerivedStateFromProps(props, state) {
    if (props.user_uuid) {
      return {
        ...state,
        uinfo: props.user_uuid
      }
    } else {
      return state;
    }
  }


  render() {
    return (
      <Routes
        uinfo={this.state.uinfo}
        {...this.props}
      />
    )
  }

}

function mapStateToProps(state) {
  return {

    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.user.role,
    user_uuid: state.auth.user.user_uid

  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
