import React, { Component } from 'react';


import _actinizationApi from '../../services/actinizationApi';


class ActinizationPageInputGroup extends Component {
  state = {
    sum: '',
    description: '',
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitHandler(e) {

    e.preventDefault();

    let { sum, description } = this.state;

    if (!sum.length > 0 || !description.length > 0) { return false }

    else {

      let data = {
        document_sum: sum,
        description: description,
        receiver: 'ООО "Спорт Вокруг."',
        competition_id: 2859,
        calendar_id: 317,
      }

      _actinizationApi.postActData(data)
        .then(result => {
          this.setState({ sum: '', description: '' })
        })

      this.props.onUpdate(true)


    }




  }

  render() {

    let { sum, description } = this.state,
      disabled = (sum.length > 0 && description.length > 0) ? '' : 'disabled';

    return (
      <div className='row'>

        <div className='col-md-12 inputRow'>
          <div>ООО «Спорт вокруг.»</div>


          <div key='sum' className="form-group">
            <input type='number'
              name='sum'

              onChange={this.handleChange}
              value={this.state.sum}
              placeholder='сумма' />
          </div>

          <div key='description' className="form-group">
            <input type='text'
              name='description'

              onChange={this.handleChange}
              value={this.state.description}
              placeholder='описание' />
          </div>

          <div className={`lk-button ${disabled}`} ><a onClick={this.submitHandler.bind(this)}>Купить</a></div>
        </div>

      </div>
    )
  }

}

export default ActinizationPageInputGroup;
