import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import _contractsApi from '../../services/contractsApi.js'
import ContractTable from './ContractTable.js';
import aux from '../Auxiliary/auxiliary.js';


class NavigationBackToMain extends Component {
  render() {
    return (
      <div className="nav-back">
        <Link to="/">вернуться в личный кабинет</Link>
      </div>
    )
  }
}


class Contracts extends Component {
  state = {
    contracts: {
      sorted: []
    },
    showAll: false,

  }

  toggleContracts = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  }

  componentDidMount() {
    if (typeof this.props.activeCalendar !== 'number') return false;
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.activeCalendar !== 'number' || prevProps.activeCalendar == this.props.activeCalendar) return false;
    this.loadData();
  }


  loadData = () => {
    let result = {},
      terminated = 0,
      sorted = [];

    _contractsApi.getContractsByCalendarId(this.props.activeCalendar, 'mainPage')
      .then(
        data => {
          data.sort(aux.contractSort)

          data.forEach(contract => {
            if (contract.status == 'terminated') {
              terminated++
            } else {
              sorted.push(contract)
            }
          })

          result.terminated = terminated;
          result.sorted = sorted;
          result.all = data;

          return result;
        }
      )
      .then(
        result => {
          this.setState({
            contracts: result
          });
        }
      )
  }

  render() {

    const { contracts, showAll } = this.state;

    return (
      <div className="wrap-inner">
        <div id="contracts" className="contracts">
          <aux.Head url="contract/new" urlTitle="заключить новый договор" img="img/contracts.jpg" headTitle="Мои договоры" />
          <ContractTable contracts={!showAll ? contracts.sorted : contracts.all} />

          {
            (contracts.terminated && contracts.terminated > 0) ? <span className='more' onClick={this.toggleContracts} >{!showAll ? 'показать недействующие договоры' : 'скрыть недействующие договоры'} ({contracts.terminated}) </span> : null
          }
        </div>
      </div>
    );
  }
}

export default Contracts;
