import React, { Component } from 'react';


const statusButtonRus = {
  success: 'принята',
  rejected: 'отклонена',
  onwaiting: 'на утверждении'
}


class StateButton extends Component {

  state = {
    showButtons: false,
  }

  mouseOverHandler = (e) => {
    this.setState({ ...this.state, showButtons: true })
  }

  mouseOut = (e) => {
    this.setState({ ...this.state, showButtons: false })
  }

  resultHandler = (e) => {

    const { name } = e.target;

    this.props.onUpdate(name)

  }

  render() {
    const { currentStatus } = this.props,
      relative = { position: 'relative' };


    const status = (<div  >
      <button className={`statusButton button-${currentStatus}`} name={currentStatus}  >{statusButtonRus[currentStatus]}</button>
    </div>
    ),

      selectStatus = (<div className='selectStatus_container' >
        <button className="statusButton" onClick={this.resultHandler} name='success'>выполнено</button>
        <button className="statusButton " onClick={this.resultHandler} name='rejected'>отказать</button>
      </div>
      );

    let { showButtons } = this.state,
      content = (!showButtons ? status : selectStatus);


    return (
      <div onMouseEnter={this.mouseOverHandler} onMouseLeave={this.mouseOut} style={relative}>

        {content}

      </div>
    )
  }
}

export default StateButton;
