import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as authActions from '../actions/authActions';
import { bindActionCreators } from 'redux';
import _authApi from '../services/authApi';
const config = require('../../../server/.config.js');


class Helper extends Component {

  componentDidMount() {
    // this.props.auth.setToken('verification_process');
    // _authApi.verification()
    //   .then(res => {
    //     if (res.data) {
    //       this.props.auth.setToken(res.data);
    //       this.props.history.push('/');
    //     }
    //     else {
    //       this.props.auth.loginOnNewWebsite();
    //     }
    //   })
  }


  render() {

    return (
      <div className="auth_wrapper">

        <div className="auth_img">

        </div>

      </div>
    )
  }

}

function mapDispatchToProps(dispatch) {
  return {

    auth: bindActionCreators(authActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(Helper)
