import React, { Component } from 'react';

import aux from '../Auxiliary/auxiliary.js';
import CompetitionTableRow from './CompetitionTableRow.js';


class CompetitionsTable extends Component {
  render() {
    const { calendar, competitions, quantity, standAloneTable } = this.props;

    return (
      <div className="">

        <table className="commonTable">
          <thead>
            <tr>
              <td className="competitionStatus"> </td>
              <td className="custom-width"></td>
              <td>
                заявок/участников
              </td>
              <td>планируемый бюджет</td>
              <td>всего собрано</td>
              <td>собрано через "Спорт вокруг."</td>
              <td>осталось вывести</td>
            </tr>
          </thead>
          <tbody>
            {competitions && competitions.map((competition, i) => {
              return <CompetitionTableRow calendar={calendar} item={competition} key={i} />
            })}
          </tbody>

        </table>

        {
          !standAloneTable ? <aux.More quantity={quantity} title='показать все мои соревнования' link='/competitions/' /> : null
        }




      </div>
    );
  }
}

export default CompetitionsTable;
