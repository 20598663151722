import React, { Component } from 'react';
import BackToMain from '../Auxiliary/BackToMain.js';
import CompetitionsTable from './CompetitionsTable.jsx';
import LoadingBlock from '../Auxiliary/LoadingBlock.js';
import { connect } from 'react-redux';

import _competitionsApi from '../../services/competitionsApi.js';
import { isError } from 'underscore';


class AllCompetitionsPage extends Component {
  state = {
    competitions: [],
    isLoadng: false,
    showFullTable: false,
    activeCalendar: 0
  }


  toggleCompetitions() {

    this.setState({
      showFullTable: !this.state.showFullTable,
    });

  }

  componentDidMount() {
    const { calendar } = this.props;
    let activeCalendar = typeof calendar === 'number' ? calendar : +localStorage.getItem('lkAC');
    if (activeCalendar === 0) return false;
    this.loadData(activeCalendar);
  }

  loadData = (activeCalendar) => {
    this.setState({
      isLoadng: true,
      isError: false,
      activeCalendar: activeCalendar
    });

    let result = {};
    let sorted = [];
    let total_withdraw = 0;

    _competitionsApi.getCompetitionsListByCalendarId(activeCalendar)
      .then(competitions => {
        for (let i = 0; i <= competitions.list.length - 1; i++) {

          if (competitions.list[i].closed == 0) {
            total_withdraw += competitions.list[i].withdraw;
            sorted.push(competitions.list[i]);
          } else {
            break; // Соревнования отсортированы по признаку closed - как только попалось первое закрытое соревнование, - дальше уже открытых не будет
          }
        }

        console.log('Итого к выводу:', total_withdraw);

        result.sorted = sorted;
        result.all = competitions.list;
        result.count = result.all.length - result.sorted.length;

        return result;
      })
      .then(
        result => {

          if (result.count !== 0) {
            this.setState({
              showFullTable: false,
              isLoadng: false,
              competitions: result
            });
          } else {
            this.setState({
              showFullTable: true,
              isLoadng: false,
              competitions: result
            });
          }
        })
        .catch(() => {
          this.setState({ ...this.state, isLoadng: false, isError: true })
        });
  }


  render() {
    let activeCalendar = typeof this.props.calendar === 'number' ? this.props.calendar : +localStorage.getItem('lkAC');
    const { showFullTable, competitions, isLoadng, isError } = this.state;
    const toggleTableLink = (competitions.count > 0 ? <span className='pseudoLink' onClick={this.toggleCompetitions.bind(this)}>{!showFullTable ? 'показать закрытые соревнования' : 'скрыть закрытые соревнования'} ({competitions.count})</span> : null);

    return (
      <div>
        <BackToMain />

        {isLoadng && !isError && <LoadingBlock />}
        {isError && <div style={{padding: '20px'}}>Не удалось загрузить данные по этому календарю. Вернитесь на главную страницу и выберите календарь, по которому вы ходтите просмотреть данные</div>}


        {!isLoadng && !isError &&
          <div>
            {toggleTableLink}
            <CompetitionsTable calendar={activeCalendar} competitions={!showFullTable ? competitions.sorted : competitions.all} standAloneTable='true' />
          </div>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    calendar: state.calendar,
  }
}

export default connect(mapStateToProps, null)(AllCompetitionsPage)
