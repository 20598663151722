import {
  CALENDAR_REQUEST,
  CALENDAR_FAIL,
  CALENDAR_SUCCESS,
} from '../constants/Calendar';

export function setCalendar(calendar) {


  return	(dispatch)	=>	{
    dispatch({
        type:	CALENDAR_REQUEST,
        payload:	calendar
    })
  }
}
