import React, { Component } from 'react';
import aux from '../Auxiliary/auxiliary.js';
import { Link } from 'react-router-dom';

class ContractTable extends Component {
  render() {
    let rows = [];
    let {contracts} = this.props;


      contracts.forEach((item, i) => {
        rows.push(<TableRow item={item} key={i} />);
      })



      return (
          <div >
              <table className="commonTable">
                  <tbody>
                  { rows }
                  </tbody>
              </table>

          </div>
      );
  }

}


class TableRow extends Component {

render(){
  const {item} = this.props,
        companyName = item.is_fiz ? (item.they_signsurname + ' ' + item.they_signname +' '+ item.they_signpatronimic) : item.they_companyname;
        //contract_date = convertDate(item.contract_date);

  const tooltipToRus = {
      signed: 'договор заключен',
      new: 'новый договор',
      terminated: 'договор расторгнут'
  }


    return(
      <tr>
        <td className={`myTooltip ${item.status}`} data-tooltip={tooltipToRus[item.status]}></td>

        <td>
          <div className='title'>

            <Link to={`/contract/${item.contract_num}`}>Договор №{item.contract_num} от {aux.convertDate(item.contract_date)}&nbsp;г.</Link>
          </div>
        </td>

        <td>
          {companyName}
        </td>

        <td>
          <div> {item.they_bankname}</div>
          <small>счёт {`...${item.they_account.slice(-4)}`} </small>
        </td>
      </tr>
    )
  }
}

export default ContractTable;
