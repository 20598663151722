import {
  SET_SUM,
  CALCULATE_SUM,
  SET_TABLE,
  UNSET_TABLE,
  SET_CONTRACT,
} from '../constants/Withdraw';

// export function setContracts(value) {
//
//   return	(dispatch)	=>	{
//     dispatch({
//         type:	SET_CONTRACT,
//         payload:	value
//     })
//   }
// }

export function setAvailableSum(value) {

  return	(dispatch)	=>	{
    dispatch({
        type:	SET_SUM,
        payload:	value
    })
  }
}

export function setWithdrawTable(value) {

  return	(dispatch)	=>	{
    dispatch({
        type:	SET_TABLE,
        payload:	value
    })
  }
}

export function unSetWithdrawTable(value) {

  return	(dispatch)	=>	{
    dispatch({
        type:	UNSET_TABLE,
        payload:	value
    })
  }
}

export function aggregateSumToWithdraw(value) {

  return	(dispatch)	=>	{
    dispatch({
        type:	CALCULATE_SUM,
        payload:	value
    })
  }
}
